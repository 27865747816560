import {
    Directive,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR
} from '@angular/forms';

import {
    FormControlDirective,
    IFormControlValidator
} from './form-control.directive';
import {
    StripeService
} from './stripe.service';

@Directive({
    selector: '[bkStripeElementsCard]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StripeElementsCardDirective),
            multi: true
        }
    ],
    exportAs: `stripeCard`
})
export class StripeElementsCardDirective implements ControlValueAccessor, OnChanges, OnInit, OnDestroy {
    card: any;
    changeEvent: any;
    complete: boolean = false;
    error: any;
    @Input('ngModel') model: any;
    @Output() onCardChange = new EventEmitter<any>();
    @Output() onCardReady = new EventEmitter<any>();
    onChange: any;
    onTouched: any;
    ready: boolean = false;
    requiredMessage: string = `Required`;
    stripeCardValidator: IFormControlValidator = {
        nullable: false,
        requirements:{
            required: {
                error: this.requiredMessage,
                fn: (val) => {
                    if (this.changeEvent) {
                        if (!this.changeEvent.error && this.changeEvent.empty) {
                            // no error, and empty
                            this.stripeCardValidator.requirements.required.error = this.requiredMessage;
                            return false;
                        }
                        if (this.changeEvent.error && !this.changeEvent.empty) {
                            // not empty, but error, so let that error take precedence in the stripe validator (below)
                            return true;
                        }
                        if (!this.changeEvent.complete) {
                            this.stripeCardValidator.requirements.required.error = 'Missing information';
                            return false;
                        }
                    }
                    return this.changeEvent?.complete;
                }
            },
            stripecard: {
                error: `stripecard error`,
                fn: (val) => {
                    if (this.changeEvent?.error) {
                        return false;
                    }
                    return true;
                },
            }
        },
        type: "string",
    };
    stripeElements: any;
    stripeToken: any;

    @Input('stripeElementsCardStyle') style: any = {
        base: {
            color: '#313131',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#a1a1a1'
            }
        },
        invalid: {
            color: 'crimson',
            iconColor: '#fa755a'
        }
    };
    
    constructor (
        public element: ElementRef,
        public SS: StripeService,
        public formControl: FormControlDirective
    ) {
        if ([null, undefined].indexOf(element.nativeElement.getAttribute('tabindex')) > -1) {
            // From old ng JS implementation:
            // oh my ... the amount of time it took to discover a missing tabindex was to blame for page-jumping on auto-completion... sheesh.
            // New notes:
            // MUST BE 0, APPARENTLY!
            element.nativeElement.setAttribute('tabindex', 0);
        }

        const stripeElements = this.stripeElements = SS.stripe.elements();

        this.card = stripeElements.create(`card`, {
            hidePostalCode: true,
            style: this.style
        });

        this.registerCardEvents();

        this.formControl.config = this.stripeCardValidator;
    }

    getStripeToken (): Promise<any> {
        const card = this.card;
        return new Promise((resolve, reject) => {
            this.SS.stripe.createToken(card).then((result) => {
                if (result.error) {
                    this.error = result.error;
                    console.error(result.error);
                    return reject(result.error);
                }
                delete this.error;
                this.stripeToken = result.token;
                return resolve(result);
            });
        });
    }

    ngOnChanges (changes: SimpleChanges): void {

    }

    ngOnDestroy (): void {
        this.card.destroy();
    }

    ngOnInit (): void {
        this.card.mount(this.element.nativeElement);
    }

    registerCardEvents (): void {
        this.card.on(`ready`, (event) => {
            this.onCardReady.emit(event);
            if (this.onChange) this.onChange(this);
            setTimeout(() => {
                this.ready = true;
            });
        });

        this.card.on(`change`, (event) => {
            delete this.stripeToken;
            this.complete = event.complete || false;
            this.changeEvent = event;
            this.onCardChange.emit(event);
            if (this.onChange) this.onChange(this);
            setTimeout(() => {
                if (event.error) {
                    this.stripeCardValidator.requirements.stripecard.error = event.error.message;
                }
                this.formControl.control.updateValueAndValidity();
            });
        });

        this.card.on(`focus`, (event) => {
            // 2020-04-19: As of writing, if a card number is entered,
            // and then the security code is subsequently changed by
            // HIGHLIGHTING a number in the code and entering a single
            // digit to update, the `change` event is NOT fired. So
            // if a validator is depending on an existing stripe token,
            // it wouldn't try to get a new one, when in fact it should.
            // So we just clear the token on card focus to be safe.
            // Might get better in the future.
            delete this.stripeToken;
        });
    }

    registerOnChange (fn: any): void {
        // ng ControlValueAccessor
        this.onChange = fn;
    }

    registerOnTouched (fn: any): void {
        // ng ControlValueAccessor
        this.onTouched = fn;
    }

    writeValue (val: any): void {
        // ng ControlValueAccessor
    }
}
