import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'friendlyDate',
    pure: false,
})
export class FriendlyDatePipe implements PipeTransform {
    transform (date: any): string {
        return moment(date).local().format('MMMM Do, YYYY');
    }
}
