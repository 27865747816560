import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { EnvService } from './env.service';
import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root'
})
export class UserService extends RestService {
    constructor (http: HttpClient, envService: EnvService) {
        super(http, envService);
        this.namespace = 'user';
    }

    checkUserExists (credentials: any): Promise<any> {
        return lastValueFrom(this.$get('exists', {params: credentials}));
    }

    sendPasswordResetLink (credentials: any): Promise<any> {
        return lastValueFrom(this.$post('send_password_reset_link', credentials)); // had {responseType:'text'} here, which broke server form errors.
    }

    signIn (credentials: any): Promise<any> {
        return lastValueFrom(this.$post('sign_in', credentials)).then((res) => {
            return this.envService.get({useraction: 1, signin: 1}).then((envRes) => {
                return res;
            });
        });
    }
}
