import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { EnvService } from 'bkng';

import { IIntegration } from '@amt/i-integration';
import { IntegrationService } from '../integration.service';

@Component({
    selector: 'app-integration-view',
    templateUrl: './integration-view.component.html',
    styleUrls: ['./integration-view.component.scss']
})
export class IntegrationViewComponent implements OnInit {
    changes = new Subject();
    fetching: boolean = false;
    integration: IIntegration = <any>{};

    constructor (
        public route: ActivatedRoute,
        public envService: EnvService,
        public integrationService: IntegrationService,
    ) {
        this.changes.pipe(
            debounceTime(250)
        ).subscribe(() => {
            this.save();
        });
    }

    debounceSave (): void {
        this.changes.next(null);
    }

    fetch (): Promise<any> {
        this.fetching = true;
        return this.integrationService.getById(this.route.snapshot.params.integrationId).then((result) => {
            Object.assign(this, result);
            return result;
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.fetching = false;
        });
    }

    ngOnInit (): void {
        this.fetch().then((result) => {
            console.log(`Integration view got`, result);
        });
    }

    save (): Promise<any> {
        return this.integrationService.save(this.integration).then((result: any) => {
            console.log("Saved integration", result);
            return result;
        });
    }
}
