import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';

@Component({
    selector: 'bk-responsive-iframe',
    templateUrl: './responsive-iframe.component.html',
    styleUrls: ['./responsive-iframe.component.scss']
})
export class ResponsiveIframeComponent implements AfterViewInit, OnDestroy, OnInit {
    @Input() allow: string;
    @Input() allowfullscreen: boolean = false;
    @Input() frameborder: number;
    height: string;
    @Input() initialHeight: number;
    @Input() initialWidth: number;
    @Input() src: string;
    @Input() title: string = '';
    width: string = `100%`;
    windowResized: (e: FocusEvent) => void;

    constructor (
        public element: ElementRef,
    ) {
    }

    ngAfterViewInit (): void {
        setTimeout(() => {
            this.setHeight();
        });
    }

    ngOnDestroy (): void {
        window.removeEventListener(`resize`, this.windowResized);
    }

    ngOnInit (): void {
        this.windowResized = (e: FocusEvent): void => {
            this.setHeight();
        }
        window.addEventListener(`resize`, this.windowResized);
    }

    setHeight (): void {
        const widthOfParentElement = this.element.nativeElement.parentNode.offsetWidth;
        const newHeight = (this.initialHeight / this.initialWidth) * widthOfParentElement;
        this.height = `${newHeight}px`;
    }
}
