import { IIntegration } from '@amt/i-integration';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-integration-preview',
    templateUrl: './integration-preview.component.html',
    styleUrls: ['./integration-preview.component.scss']
})
export class IntegrationPreviewComponent implements OnInit {
    @Input() integration: IIntegration;

    constructor () { }

    ngOnInit (): void {
    }

}
