<div class="interface-sidenav-container" [ngClass]="{open: drawer.opened, closed: !drawer.opened}">
    <div class="sidenav-toggle-button-inside-holder">
        <div class="sidenav-toggle-button" (click)="drawer.toggle() && dispatchWindowResizedEvent()">
            <mat-icon *ngIf="!drawer.opened">chevron_right</mat-icon>
            <mat-icon *ngIf="drawer.opened">close</mat-icon>
        </div>
    </div>

    <div class="container-sidenav-top-logo"></div>

    <div class="drawer-nav">
        <a class="drawer-nav-item" routerLink="/" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
            <div class="indicator"><mat-icon>chevron_right</mat-icon></div><span>Home</span>
        </a>
        <a class="drawer-nav-item" routerLink="/integrations" [routerLinkActive]="['active']" [routerLinkActiveOptions]="">
            <div class="indicator"><mat-icon>chevron_right</mat-icon></div><span>Integrations</span>
        </a>
    </div>

    <div class="spacer"></div>

    <div class="bow-tam">
        <div class="user-wrap">
            <div *ngIf="env.user.data?.googleProfile?.picture" class="user-image" [ngStyle]="{'background-image': 'url(' + env.user.data.googleProfile.picture + ')'}"></div>
            <div *ngIf="!env.user.data?.googleProfile?.picture" class="user-image"></div>
            <div class="user-name" [matMenuTriggerFor]="menu">
                <span>{{env.user.name}}</span>
            </div>
            <mat-menu #menu="matMenu">
                <a mat-menu-item href="/auth/user/logout">Log out</a>
            </mat-menu>
        </div>
    </div>
</div>
