import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { EnvService } from 'bkng';

import { ApplicationService } from '../application.service';

import { IRouteApiAppById } from '@arhat/i-route-api-app-by-id';

@Component({
    selector: 'app-application-view',
    templateUrl: './application-view.component.html',
    styleUrls: ['./application-view.component.scss']
})
export class ApplicationViewComponent implements OnInit, OnDestroy {
    data: IRouteApiAppById;
    env: any;
    routerClass: string = 'hidden';

    constructor (
        public route: ActivatedRoute,
        public router: Router,
        public envService: EnvService,
        public applicationService: ApplicationService,
    ) {
        this.env = envService.data;

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.routerClass = `application-view-router-${this.route.firstChild.routeConfig.path ? this.route.firstChild.routeConfig.path : 'logs'}`;
            }
        });
    }

    ngOnDestroy (): void {
    }

    ngOnInit (): void {
        this.applicationService.getById(this.route.snapshot.params.applicationId).then((result: IRouteApiAppById) => {
            console.log(`Application view got:`, result);
            this.data = result;
        }).catch((err) => {
            console.error(err);
        });
    }

    showLogFilters (): boolean {
        if (this.route.snapshot?.firstChild?.url?.length) {
            return this.route.snapshot?.firstChild?.url[0]?.path === '';
        }
        return true;
    }
}
