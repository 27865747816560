export class WordCount {
    constructor () {}

    count (str: string): number {
        str = (str || '').trim().replace(/\s\s+/g, ' ');

        if (str.length === 0) {
            return 0;
        }

        const words = str.split(' ');

        return words.length;
    }
}
