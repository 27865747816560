<div class="application-log-container" *ngIf="log?.id">
    <div class="application-log-first-of-day" *ngIf="isFirstOfDay">
        <span>{{log.time | friendlyDate}}</span>
    </div>
    <div class="application-log"> <!-- *ngIf="!isFirstOfDay" -->
        <div class="application-log-time">
            <!-- <div class="log-date">{{log.time | logDate}}</div> -->
            <div class="log-time" (click)="toggleOpen()">{{log.time | logTime}}</div>
        </div>
        <div *ngIf="log.data.users?.length" class="application-log-user">
            <span [title]="(log.data.users[0].name || log.data.users[0].id)">{{log.data.users[0].email || log.data.users[0].name || log.data.users[0].id}}</span>
        </div>
        <!-- <div class="application-log-type">
            <div>{{log.application_log_type.name}}</div>
        </div> -->
        <div class="application-log-data" *ngIf="!isApi(log)">
            <pre [innerHtml]="log.data.string" *ngIf="log.data.string"></pre>
            <pre [innerHtml]="log | stirfry" *ngIf="!log.data.string"></pre>
        </div>
        <div class="application-log-data rest-api" *ngIf="isApi(log)">
            <div class="rest-api-log-method">{{log.data.request.method}}</div>
            <div class="rest-api-log-url">{{log.data.request.url}}</div>
            <div class="rest-api-log-statusCode">
                <b>{{log.data.response.statusCode}}</b>
            </div>
            <div class="rest-api-log-took">{{log.data.response.took}} ms</div>
        </div>
    </div>
    <div class="log-data" *ngIf="open">
        <pre [innerHtml]="log.data | stirfry"></pre>
    </div>
</div>
