import {
    Directive,
    ElementRef,
    OnInit
} from '@angular/core';

import {
    Blocker,
    LoadBlockerService
} from './load-blocker.service';

@Directive({
    selector: '[bkLoadBlocker]'
})
export class LoadBlockerDirective implements OnInit {
    blocker: Blocker;

    constructor (
        public lbs: LoadBlockerService,
        private element: ElementRef
    ) {
    }

    ngOnInit (): void {
        const blocker = this.blocker = this.lbs.register({
            blockerDirective: this
        });
        if (this.element.nativeElement.tagName === 'IMG') {
            this.element.nativeElement.onload = (e) => {
                blocker.unblock();
            }
            this.element.nativeElement.onerror = (e) => {
                blocker.unblock();
            }
        }
    }
}
