import { Directive, Input } from '@angular/core';
import {
    AbstractControl,
    NG_VALIDATORS,
    Validator,
} from '@angular/forms';
import { WordCount } from './word-count';

@Directive({
    selector: '[bkWordCount]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: WordCountDirective,
            multi: true
        }
    ]
})
export class WordCountDirective implements Validator {
    wordCounter = new WordCount();
    @Input() wordMax: number;

    constructor () {
    }

    typingStopper (event: KeyboardEvent): void {
        // in case this is ever a good idea.

        // (keyup)="wordCounter($event)"

        /*
        const lowerKey = event.key.toLowerCase();
        // console.log('key', lowerKey);
        const numWords = 0;
        if (
            ![
                `delete`,
                `backspace`,
                `arrowup`,
                `arrowdown`,
                `arrowleft`,
                `arrowright`,
            ].includes(lowerKey)
            &&
            !(
                event.ctrlKey
                &&
                [
                    `a`,
                    `x`,
                    `c`,
                ].includes(lowerKey)
            )
            &&
            this.campaignComponentField.options.maxWords
        ) {
            if (numWords > this.campaignComponentField.options.maxWords) {
                event.preventDefault();
            }
        }
        */
    }

    validate (control: AbstractControl): { [key: string]: any } | null {
        if (this.wordMax) {
            const maxWords = this.wordMax;
            const numWords = this.wordCounter.count(control.value);
            if (numWords > maxWords) {
                return {
                    // maxWordCount: `${numWords} word${numWords > 1 ? 's' : ''} exceeds maximum.`,
                    maxWordCount: `Exceeds maximum word count`,
                };
            }
        }
        return null;
    }
}
