import {
    Component,
    OnInit
} from '@angular/core';

import { EnvService } from 'bkng';

@Component({
    selector: 'app-interface',
    templateUrl: './interface.component.html',
    styleUrls: ['./interface.component.scss']
})
export class InterfaceComponent implements OnInit {
    env: any = {};

    constructor (
        public envService: EnvService,
    ) {
        this.env = envService.data;
    }

    ngOnInit (): void {
    }
}
