import { Component, Input, OnInit } from '@angular/core';

import { ILog } from '@amt/i-log';

@Component({
    selector: 'app-application-log',
    templateUrl: './application-log.component.html',
    styleUrls: ['./application-log.component.scss']
})
export class ApplicationLogComponent implements OnInit {
    @Input() isFirstOfDay: boolean;
    @Input() log: ILog;
    open: boolean = false;

    constructor () { }

    isApi (log: ILog): boolean {
        return [
            'rest-api',
            'rest-api-error',
        ].includes(log.application_log_type.name);
    }

    ngOnInit (): void {
    }

    toggleOpen (): void {
        this.open = !this.open;
    }
}
