<div class="application-settings-container" *ngIf="data?.application?.id">
    <div>
        <div>Application ID: {{data.application.id}}</div>
    </div>
    <div class="application-settings-application-label-input">
        <div>
            <mat-form-field>
                <mat-label>Application Label</mat-label>
                <input placeholder="i.e. GMS" type="text" [(ngModel)]="data.application.label" [name]="'ApplicationLabel'" matInput (ngModelChange)="saveSubject.next(null)" />
            </mat-form-field>
        </div>
    </div>
    <div class="application-settings-application-label-input">
        <div>
            <mat-form-field>
                <mat-label>Max Logs</mat-label>
                <input placeholder="i.e. 55500" type="number" [(ngModel)]="data.application.data.maxLogs" [name]="'ApplicationMaxLogs'" matInput (ngModelChange)="saveSubject.next(null)" />
            </mat-form-field>
        </div>
    </div>
    <div class="application-settings-application-label-input">
        <div>
            <mat-form-field>
                <mat-label>Slack Integration</mat-label>
                <mat-select [name]="'SelectedSlackIntegration'" [(ngModel)]="data.application.data.slackIntegrationId" (ngModelChange)="changedSlackIntegration()">
                    <mat-option *ngFor="let integ of data.slackIntegrations" [value]="integ.id">
                        <span>{{integ.label}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <app-slack-channel-select
                *ngIf="!changingSlackIntegration && data.application.data.slackIntegrationId"
                [name]="'SlackIntegrationChannelId'"
                [integrationId]="data.application.data.slackIntegrationId"
                [(ngModel)]="data.application.data.slackChannelId"
                (ngModelChange)="saveSubject.next(null)"
            ></app-slack-channel-select>
        </div>
        <div *ngIf="data.application.data.slackIntegrationId" style="padding-bottom: 35px;">
            <span class="link" (click)="clearSlackIntegration()">Clear Slack Integration</span>
        </div> 
    </div>
    <div class="application-settings-application-keys">
        <div>Application Keys:</div>
        <div>
            <div *ngFor="let ak of data.application.keys">
                <span>{{ak.id}} / {{ak.secret}}</span>
            </div>
        </div>
    </div>
    <div class="application-settings-danger-zone">
        <div>
            <div class="clear-app-logs">
                <button mat-raised-button color="warn" (click)="clearApplicationLogs()" [disabled]="clearingLogs">{{wantToClearLogs ? 'Click again to clear' : 'Clear Logs'}}</button>
            </div>
            <div *ngIf="wantToClearLogs">
                <span>This will clear all application logs.</span>
            </div>
        </div>

        <div>
            <div class="delete-app">
                <button mat-raised-button color="warn" (click)="deleteApplication()" [disabled]="(wantToDelete && (deleteAppId !== data.application.id)) || deletingApp">Delete</button>
            </div>
            <div *ngIf="wantToDelete">
                <mat-form-field>
                    <mat-label>Enter app id to delete</mat-label>
                    <input type="text" [(ngModel)]="deleteAppId" name="DeleteAppId" matInput />
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
