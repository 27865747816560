import {
    Pipe,
    PipeTransform
} from '@angular/core';

import {
    EnvService
} from './env.service';

@Pipe({
    name: 'countryCodeToName'
})
export class CountryCodeToNamePipe implements PipeTransform {
    env: any;

    constructor (
        public envService: EnvService
    ) {
        this.env = envService.data;
    }

    transform (countryCode: string, ...args: unknown[]): string {
        if (!countryCode) {
            return;
        }
        const env = this.env;
        const countryObj = env.countries.filter((c) => {
            return c.code.toUpperCase() == countryCode.toUpperCase();
        })[0];
        return countryObj.name;
    }
}