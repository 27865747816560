import { Pipe, PipeTransform } from '@angular/core';
import { WordCount } from './word-count';

@Pipe({
    name: 'wordCount',
})
export class WordCountPipe implements PipeTransform {
    wordCounter = new WordCount();

    constructor () {}

    transform (value: string): number {
        return this.wordCounter.count(value);
    }
}
