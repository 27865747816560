import {
    Pipe,
    PipeTransform
} from '@angular/core';

@Pipe({
    name: 'stirfry',
    pure: false,
})
export class StirfryPipe implements PipeTransform {
    transform (obj: any, ...args: any[]): string {      
        const stringifiedJson = JSON.stringify(obj, null, 4);
        //console.log(stringifiedJson);
        return stringifiedJson;
    }
}
