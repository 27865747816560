import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import {
    ControlContainer,
    UntypedFormGroup,
    NgForm,
} from '@angular/forms';

import {
    EnvService
} from '../env.service';

@Component({
    selector: 'bk-phone-number-input',
    templateUrl: './phone-number-input.component.html',
    styleUrls: ['./phone-number-input.component.scss'],
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class PhoneNumberInputComponent implements OnInit {
    @Input() disabled: boolean = false;
    env: any= {};
    @Input() name: string = 'InputPhoneNumber';
    @Input() label: string = 'Phone Number';
    @Input() parentForm: UntypedFormGroup = new UntypedFormGroup({});
    @Input() phone: {
        country_code: string;
        value: string;
    };
    @Output() pressedEnter = new EventEmitter<any>();
    @Input() required;

    constructor (
        public element: ElementRef,
        public envService: EnvService
    ) {
        this.env = envService.data;
        
        if ([null, undefined].indexOf(element.nativeElement.getAttribute('required')) < 0) {
            this.required = true;
        }
    }

    ngOnInit (): void {
    }
}
