import {
    ElementRef,
    Injectable,
    Renderer2
} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ContentService {
    constructor () {

    }

    ensureElementOnScreen (element: ElementRef, renderer: Renderer2): () => void {
        let rect = element.nativeElement.getBoundingClientRect();
        let diff = (rect.x + rect.width) - window.innerWidth;
        
        if (diff >= -15) {
            renderer.setStyle(element.nativeElement, 'left', `auto`);
            renderer.setStyle(element.nativeElement, 'right', `0px`);
            
            rect = element.nativeElement.getBoundingClientRect();

            diff = (rect.x + rect.width) - window.innerWidth;

            const pos = diff + 30;

            renderer.setStyle(element.nativeElement, 'right', `${pos}px`);
        }

        return () => {
            renderer.setStyle(element.nativeElement, 'left', null);
            renderer.setStyle(element.nativeElement, 'right', null);
        };
    }
}
