import {
    HTTP_INTERCEPTORS,
    HttpClientModule
} from '@angular/common/http';
import {
    NgModule,
    APP_INITIALIZER,
    ErrorHandler
} from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
    BkErrorHandler,
    BkngModule,
    EnvService,
    HttpXsrfInterceptor
} from 'bkng';

import { AppRoutingModule } from './app-routing.module';
import { AngularMaterialModule } from './angular-material.module';

import { AppComponent } from './app.component';
import { HomeRouteComponent } from './home/home.component';
import { InterfaceComponent } from './interface/interface.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { InterfaceSidenavComponent } from './interface-sidenav/interface-sidenav.component';
import { ListItemApplicationComponent } from './list-item-application/list-item-application.component';
import { ApplicationViewComponent } from './application-view/application-view.component';
import { ApplicationLogsComponent } from './application-logs/application-logs.component';
import { ApplicationSettingsComponent } from './application-settings/application-settings.component';
import { ApplicationViewLogsComponent } from './application-view-logs/application-view-logs.component';
import { LogFiltersComponent } from './log-filters/log-filters.component';
import { ApplicationLogComponent } from './application-log/application-log.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { CreateNewIntegrationDialogComponent } from './create-new-integration-dialog/create-new-integration-dialog.component';
import { IntegrationListItemComponent } from './integration-list-item/integration-list-item.component';
import { IntegrationPreviewComponent } from './integration-preview/integration-preview.component';
import { IntegrationDetailsComponent } from './integration-details/integration-details.component';
import { IntegrationViewComponent } from './integration-view/integration-view.component';
import { SlackChannelSelectComponent } from './slack-channel-select/slack-channel-select.component';

export function initApp (envService: EnvService): any {
    return (): Promise<any> => {
        return envService.bootstrap();
    };
}

@NgModule({
    declarations: [
        AppComponent,
        HomeRouteComponent,
        InterfaceComponent,
        LoginPageComponent,
        InterfaceSidenavComponent,
        ListItemApplicationComponent,
        ApplicationViewComponent,
        ApplicationLogsComponent,
        ApplicationSettingsComponent,
        ApplicationViewLogsComponent,
        LogFiltersComponent,
        ApplicationLogComponent,
        IntegrationsComponent,
        CreateNewIntegrationDialogComponent,
        IntegrationListItemComponent,
        IntegrationPreviewComponent,
        IntegrationDetailsComponent,
        IntegrationViewComponent,
        SlackChannelSelectComponent,
    ],
    imports: [
        HttpClientModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        FormsModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        BkngModule,
        AppRoutingModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpXsrfInterceptor,
            multi: true
        },
        {provide: ErrorHandler, useClass: BkErrorHandler},
        EnvService,
        {
            deps: [
                EnvService
            ],
            provide: APP_INITIALIZER,
            useFactory: initApp,
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
