import {
    Directive,
    Input,
    OnInit
} from '@angular/core';

import {
    Blockers,
    LoadBlockerService
} from './load-blocker.service';

@Directive({
    selector: '[bkLoadBlock]',
    exportAs: 'bkLoadBlock'
})
export class LoadBlockDirective implements OnInit {
    @Input() bkLoadBlock: any; // remember: this kind of thing won't always (or ever) be visible in constructor, but should be visible in ngOnInit.
    blockers: Blockers;

    constructor (
        public lbs: LoadBlockerService
    ) {
        this.blockers = lbs.blockers;
    }

    ngOnInit (): void {

    }
}
