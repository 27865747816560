import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
} from '@angular/core';

import {
    ControlContainer,
    ControlValueAccessor,
    UntypedFormControl,
    UntypedFormGroup,
    NG_VALUE_ACCESSOR
} from '@angular/forms';

import { ContentService } from '../content.service';

import { SelectDisplayDirective } from './select-display.directive';
import { SelectInputDirective } from './select-input.directive';
import { SelectListHolderDirective } from './select-list-holder.directive';

export interface ISelectListItem {
    data: any;
    element?: ElementRef;
    emptyListItem?: boolean;
    focus?: () => any;
    key: string;
    queryingListItem?: boolean;
    showing: boolean;
}

export interface ISelectListOptions {
    displayFn?: (SelectListItem) => string;
    displayKey?: string;
    notNull?: boolean;
    testKeys?: Array<string>;
}

export interface IAddNew {
    searchText: string;
    selectComponent: SelectComponent;
}

@Component({
    selector: 'bk-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    exportAs: 'SelectComponent',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true
        }
    ],
})
export class SelectComponent implements ControlValueAccessor, OnInit, OnChanges {
    $$bounce_interval: any;
    @Output() addNew = new EventEmitter<IAddNew>();
    @Input('selectListClearedValue') clearedValue: any;
    @Input() disabled: boolean = false;
    hasFocus: boolean = false;
    @Output() itemChange = new EventEmitter<any>();
    list: Array<ISelectListItem>;
    listObj: {[pk: string]: ISelectListItem} = {};
    listShowing: Array<ISelectListItem> = [];
    listShowingKeys: Array<string>;
    @Input() name: string = '';
    @Input('ngModel') model: any;
    okToDisplay: boolean = false;
    onChange;
    onTouched;
    open: boolean = false;
    @Input('selectListOptions') options: ISelectListOptions = {};
    @Input('selectListPrimaryKey') primaryKey: string;
    protected: boolean = false;
    protectedFromBlur: boolean = false;
    searchText: string = '';
    searchTextWas: string = '';
    selectedItem: any;
    @Input() selectList: any = [];
    stopKeepOnScreen: any;
    suggestedResult: any;
    suggestedResultText: string;
    toDisplay: string = '';

    @ViewChild('selectDisplay') selectDisplay: SelectDisplayDirective;
    @ViewChild('selectInput') selectInput: SelectInputDirective;
    @ViewChild('selectListHolder') selectListHolder: SelectListHolderDirective;

    get form (): UntypedFormGroup {
        return this.controlContainer.control as UntypedFormGroup;
    }
    
    get control (): UntypedFormControl {
        return this.form.get(this.name) as UntypedFormControl;
    }

    constructor (
        public element: ElementRef,
        public renderer: Renderer2,
        public CS: ContentService,
        public controlContainer: ControlContainer,
    ) {
    }

    registerOnChange (fn: any): void {
        // ng ControlValueAccessor
        this.onChange = fn;
    }

    registerOnTouched (fn: any): void {
        // ng ControlValueAccessor
        this.onTouched = fn;
    }

    display (item: ISelectListItem): string {
        if (this.options.displayFn) {
            return this.options.displayFn(item);
        }
        if (item && item.data) {
            if (this.options.displayKey) {
                return item.data[this.options.displayKey];
            }
            if (item.data.name) {
                return item.data.name
            }
        }
        return '';
    }

    filterListAndSelectByKey (searchText: string, key: string): Promise<ISelectListItem> {
        return this.filterList(searchText).then(() => {
            return this.selectItemByKey(key);
        });
    }

    filterList (searchText: string): Promise<Array<ISelectListItem>> {
        this.makeSuggestion();
        return this.getList().then((list: any) => {
            const stStr = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const regex = new RegExp('^' + stStr, 'i');
            
            this.listShowingKeys = [];

            this.listShowing.splice(0, this.listShowing.length);

            list.forEach((item: ISelectListItem) => {
                if (!searchText || (typeof(this.selectList) === 'function')) {  // to do: implement proper input for list-get functions!
                    // don't test. list all.
                    item.showing = true;
                    this.listShowing.push(item);
                    this.listShowingKeys.push(item.key);
                    return true;
                }

                let show = false;
                const testKeys = this.options.testKeys;
                for (const tk of testKeys) {
                    if (regex.test(item.data[tk])) {
                        show = true;
                        break;
                    }
                }

                item.showing = show;
                
                if (item.showing) {
                    this.listShowing.push(item);
                    this.listShowingKeys.push(item.key);
                }

                return show;
            });

            this.makeSuggestion();

            return list;
        }).catch((err) => {
            return;
        });
    }

    getItemValue (item: any): any {
        const key = this.primaryKey || 'id';
        let value;
        if (item && item.data) {
            value = item.data[key];
        }
        return value;
    }

    getList (): Promise<Array<ISelectListItem>> {
        if (typeof(this.selectList) === 'function') {
            if (this.searchText == this.searchTextWas) {
                return Promise.resolve(this.list);
            }

            if (this.$$bounce_interval) {
                return Promise.reject();
            }

            const $$bounced_at = new Date();

            return new Promise((resolve, reject) => {
                this.$$bounce_interval = setInterval(() => {
                    const now = new Date();
                    if ((now.getTime() - $$bounced_at.getTime()) >= 199) {
                        clearInterval(this.$$bounce_interval);
                        // delete this.$$bounced_at;
                        delete this.$$bounce_interval;
                        this.searchTextWas = this.searchText;
                        return this.selectList({
                            searchText: this.searchText,
                            selected: this.selectedItem ? [this.selectedItem.data] : undefined
                        }).then((result) => {
                            if (this.$$bounce_interval) {
                                return this.list;
                            }
                            return this.prepList(result).then(resolve);
                        });
                    }
                }, 50);
            });
        }
        return this.prepList(this.selectList);
    }

    getNextShowingListItem (startingFromItem?: ISelectListItem): ISelectListItem {
        let item: ISelectListItem;
        let startingIndex = 0;
        if (startingFromItem) {
            startingIndex = this.list.indexOf(startingFromItem) + 1;
        }
        for (let i = startingIndex; i < this.list.length; i++) {
            const _item = this.list[i];
            if (_item.showing) {
                item = _item;
                break;
            }
        }
        return item;
    }

    getPrevShowingListItem (startingFromItem?: ISelectListItem): ISelectListItem {
        let item: ISelectListItem;
        let startingIndex = this.list.length - 1;
        if (startingFromItem) {
            startingIndex = this.list.indexOf(startingFromItem) - 1;
        }
        for (let i = startingIndex; i >= 0; i--) {
            const _item = this.list[i];
            if (_item.showing) {
                item = _item;
                break;
            }
        }
        return item;
    }

    makeSuggestion (item: any = undefined): void {
        delete this.suggestedResult;
        delete this.suggestedResultText;
        if (item) {
            if (!item.action && !item.emptyListItem) {
                this.suggestedResult = item;
                this.suggestedResultText = this.suggestedDisplay(item);
            }
        }
        else if (this.searchText.length) {
            if (this.listShowing[0]) {
                const txt = this.suggestedDisplay(this.listShowing[0]);
                const startingPortion = txt.substring(0, this.searchText.length);
                if (this.searchText.toLowerCase() == startingPortion.toLowerCase()) {
                    this.suggestedResult = this.listShowing[0];
                    this.suggestedResultText = this.searchText + txt.substring(this.searchText.length, txt.length);
                }
            }
        }
        else {
            this.suggestedResultText = 'Type here...';
        }
    }

    ngOnChanges (changes: SimpleChanges): void {
    }

    ngOnInit (): void {
        setTimeout(() => {
            this.okToDisplay = true;

            if (this.options.notNull === undefined) {
                this.options.notNull = false;
            }
            if (this.options.testKeys === undefined) {
                this.options.testKeys = ['name'];
            }
            this.selectItemByValue(this.model);
        }, 1);
    }

    getNewListItem (item: any): ISelectListItem {
        const sli: ISelectListItem = {
            data: item,
            key: item[this.primaryKey],
            showing: false
        };
        sli.focus = () => {
            sli.element?.nativeElement.focus();
        };
        return sli;
    }

    addItemToListObject (item: ISelectListItem): ISelectListItem {
        this.listObj = this.listObj || {};
        if (this.listObj[item.key]) {
            throw new Error(`An item with primary key '${item.key}' already exists in the list items object.`);
        }
        this.listObj[item.key] = item;
        return item;
    }

    prepList (listToPrep: Array<ISelectListItem> = []): Promise<Array<ISelectListItem>> {
        if (!this.primaryKey) {
            throw new Error(`${this.constructor.name}: Please define a primary key with [selectListPrimaryKey]="'yourPrimaryKey'".`);
        }
        if (this.list) {
            const newListKeys = listToPrep.map(item => item[this.primaryKey]);
            this.list.forEach((li: ISelectListItem, liIndex: number) => {
                if (newListKeys.indexOf(li.key) < 0) {
                    this.list.splice(liIndex, 1);
                    delete this.listObj[li.key];
                }
            });
            listToPrep.forEach((li) => {
                const pk = li[this.primaryKey];
                if (!this.listObj[pk]) {
                    this.addItemToListObject(this.getNewListItem(li));
                }
            });
        }
        else {
            this.list = listToPrep.map((item) => {
                return this.addItemToListObject(this.getNewListItem(item));
            });
        }
        return Promise.resolve(this.list);
    }

    selectItem (item?: any): void {
        let modelValueToSet = this.clearedValue;
        
        if (item && item.action) {
            item.action()
        }
        else if (item && !item.emptyListItem) {
            modelValueToSet = this.getItemValue(item);
        }
        else {
            delete this.selectedItem;
            this.itemChange.emit();
        }

        let ok = (item && (item.action || item.emptyListItem)) ? false : true;

        if ([null, undefined].indexOf(modelValueToSet) > -1) {
            if (this.options.notNull) {
                ok = false
            }
        }

        if (ok) {
            this.selectedItem = item;
            this.itemChange.emit(item);

            this.toDisplay = this.display(item);

            if (this.onChange && (modelValueToSet !== this.model)) {
                this.onChange(modelValueToSet);
            }
        }

        if (this.selectedItem) {
            this.searchText = this.suggestedDisplay(this.selectedItem);
        }

        if (this.open && (item && !item.emptyListItem)) {
            this.toggle(false);
        }

        // scope.inputElement[0].selectionStart = scope.inputElement[0].selectionEnd = scope.searchText.length; # causing jump-down issues on iOS.
    }

    getItemByValue (val: any = this.clearedValue): Promise<any> {
        return this.getList().then((list: any) => {
            const item = list.filter((li) => {
                return val === this.getItemValue(li);
            })[0];
            return item;
        });
    }

    selectItemByKey (key: string): Promise<ISelectListItem> {
        const item = this.listObj[key];
        if (item) {
            this.selectItem(item);
        }
        return Promise.resolve(item);
    }

    selectItemByValue (val: any = this.clearedValue): Promise<any> {
        return this.getItemByValue(val).then((item) => {
            this.selectItem(item);
            return item;
        });
    }

    suggestedDisplay (item: any): string {
        return this.display(item);
    }

    toggle (tog: boolean): boolean {
        if (this.disabled) {
            return null;
        }

        const _event: any = event; // survive after promise

        const wasOpen: boolean = this.open;

        if (typeof(tog) != 'undefined') {
            this.open = tog ? true : false;
        }
        else {
            this.open = !this.open;
            tog = this.open;
        }

        if (tog) {
            // this.searchText = scope.suggestedDisplay() #should probably be a clear-on-click function...
            
            if (!_event.ctrlKey && !_event.metaKey) {
                this.searchText = '';
            }
            setTimeout(() => {
                this.filterList(this.searchText).then((r) => {
                    this.open = true;
                    if (!_event.ctrlKey && !_event.metaKey) {
                        this.selectInput.el.nativeElement.selectionStart = this.selectInput.el.nativeElement.selectionEnd = this.searchText.length;
                    }
                    this.stopKeepOnScreen = this.CS.ensureElementOnScreen(this.selectListHolder.el, this.renderer);
                });
            });
        }
        else {
            this.open = false;
            delete this.protected;

            if (wasOpen) {
                if (_event) {
                    _event.preventDefault();
                }
                setTimeout(() => {
                    this.selectDisplay.el.nativeElement.focus();
                });
            }

            if (this.searchText !== this.suggestedResultText) {
                this.searchText = '';
            }

            if (typeof(this.stopKeepOnScreen) == 'function') {
                this.stopKeepOnScreen();
            }
        }
        return tog;
    }

    writeValue (val: any): void {
        // ng ControlValueAccessor
        if (
            (this.onChange && this.onTouched)
        ) {
            // Not sure if this is the right approach.
            // Found a bug where writeValue was called with null before onChange & onTouched were registered
            // Caused null/undefined to be written to model erroneously, and then the actual model value was
            // written immediately afterward, triggering an ngModelChange event to be fired.
            this.selectItemByValue(val);
        }
    }

    onCursorDownHandler (e: any): void {
        if (this.open) {
            this.protectedFromBlur = true;
        }
    }

    @HostListener('mousedown', ['$event'])
    onMouseDownHandler (e: MouseEvent): void {
        this.onCursorDownHandler(e);
    }

    @HostListener('touchstart', ['$event'])
    onTouchStartHandler (e: TouchEvent): void {
        this.onCursorDownHandler(e);
    }

    onCursorUpHandler (e: any): void {
        if (this.open) {
            this.protectedFromBlur = false;
        }
    }

    @HostListener('mouseup', ['$event'])
    onMouseUpHandler (e: MouseEvent): void {
        this.onCursorUpHandler(e);
    }

    @HostListener('touchend', ['$event'])
    onTouchEndHandler (e: TouchEvent): void {
        this.onCursorUpHandler(e);
    }

    @HostListener('keydown', ['$event'])
    onKeyDownHandler (e: KeyboardEvent): void {
        if ([13].indexOf(e.keyCode) > -1) {
            // carriage return
            e.stopPropagation();
            e.preventDefault();
        }
    }
}
