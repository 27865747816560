import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'daysSinceDate',
    pure: false,
})
export class DaysSinceDatePipe implements PipeTransform {
    transform (date: any): string {
        return moment((new Date())).diff(date, 'days').toString();
    }
}
