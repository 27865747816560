import {
    Directive,
    ElementRef,
    EventEmitter,
    Output,
    Renderer2,
} from '@angular/core';
import {
    NgForm
} from '@angular/forms';

import { FormControlDirective } from './form-control.directive';

@Directive({
    selector: '[bkForm]',
    exportAs: 'bkForm'
})
export class FormDirective {
    @Output() bkSubmit = new EventEmitter<any>();
    @Output() bkFormUpdated = new EventEmitter<any>();

    controls: Array<FormControlDirective> = [];
    error: boolean = false;
    serverErrorMessages: any;
    serverFormErrors: any;
    submissionAttempted: boolean = false;
    submitting: boolean = false;

    constructor (
        public ngForm: NgForm,
        private element: ElementRef,
        private renderer: Renderer2
    ) {
    }

    onError (err?: any): void {
        if (err && err.error) {
            if (err.error.formErrors) {
                this.serverFormErrors = err.error.formErrors;
            }
            if (err.error.messages) {
                this.serverErrorMessages = err.error.messages;
            }
            else if (err.error.error) {
                this.serverErrorMessages = [err.error.error.message || err.error.error];
            }
        }
        this.submitHalted(); // last line.
    }

    onSuccess (): void {
        this.submitHalted(); // last line.
    }

    registerFormControl (control: FormControlDirective): void {
        this.controls.push(control);
    }

    submit (): Promise<boolean> {
        if (this.submitting) {
            return Promise.resolve(false); // already submitting
        }

        this.renderer.addClass(this.element.nativeElement, 'form-submission-attempted');

        this.submissionAttempted = true;
        this.submitting = true;

        if (this.ngForm.form.status == 'INVALID') {
            this.submitHalted();
            return Promise.resolve(false); // invalid
        }

        delete this.serverErrorMessages;
        delete this.serverFormErrors;
        
        this.bkSubmit.emit();
        this.submitHalted();

        return Promise.resolve(true);
    }

    private submitHalted () {
        this.submitting = false;

        if ((this.ngForm.form.status == 'INVALID') || this.serverFormErrors) {
            this.renderer.addClass(this.element.nativeElement, 'error');
        }
        else {
            this.renderer.removeClass(this.element.nativeElement, 'error');
        }

        this.bkFormUpdated.emit();
        
        for (let i = 0; i < this.controls.length; i++) {
            const kontrol = this.controls[i];
            if (kontrol.control?.errors) {
                kontrol.element.nativeElement.focus();
                break;
            }
        }
    }
}
