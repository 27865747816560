import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { EnvService } from 'bkng';

import { IApplication } from '@amt/i-application';

import { ApplicationService } from '../application.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeRouteComponent implements OnInit {
    applications: Array<IApplication> = [];
    env: any;
    fetching: boolean = false;
    fetched: boolean = false;
    
    constructor (
        public route: ActivatedRoute,
        public envService: EnvService,
        public applicationService: ApplicationService,
    ) {
        this.env = envService.data;
    }

    createApp (): void {
        this.applicationService.createApplication().then((result) => {
            console.log('Created app', result);
            this.applications.unshift(result.application);
        }).catch((err) => {
            console.error(err);
        });
    }

    ngOnInit (): void {
        this.fetching = true;
        this.applicationService.get().then((result) => {
            console.log('Apps got:', result);
            Object.assign(this, result);
            this.fetched = true;
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.fetching = false;
        });
    }
}
