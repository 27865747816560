import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IRouteApiAppById } from '@arhat/i-route-api-app-by-id';

import { EnvService } from 'bkng';

@Component({
    selector: 'app-log-filters',
    templateUrl: './log-filters.component.html',
    styleUrls: ['./log-filters.component.scss']
})
export class LogFiltersComponent implements OnInit {
    @Input() data: IRouteApiAppById;
    filters: {
        class?: string;
        since?: string;
    } = {};
    @Output() update = new EventEmitter<any>();

    constructor (
        public route: ActivatedRoute,
        public router: Router,
        public envService: EnvService,
    ) {}

    ngOnInit (): void {
        if (this.route.snapshot.queryParams.class) {
            this.filters.class = this.route.snapshot.queryParams.class;
        }

        if (this.route.snapshot.queryParams.since) {
            const val = this.route.snapshot.queryParams.since;
            // horror show
            const d = new Date(val);
            let month = `${d.getMonth() + 1}`;
            if (month.length < 2) {
                month = `0${month}`;
            }
            let day = `${d.getDate()}`;
            if (day.length < 2) {
                day = `0${day}`;
            }
            let hours = `${d.getHours()}`;
            if (hours.length < 2) {
                hours = `0${hours}`;
            }
            let minutes = `${d.getMinutes()}`;
            if (minutes.length < 2) {
                minutes = `0${minutes}`;
            }
            this.filters.since = `${d.getFullYear()}-${month}-${day}T${hours}:${minutes}`;
        }
    }

    updateFilters (): void {
        const newParams = Object.assign({}, this.filters);
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: newParams,
        }).then(() => {
            this.update.emit(this.filters);
        });
    }
}
