<div class="application-logs-container">
    <div class="application-logs-list" #logsList>
        <div *ngIf="fetchingLogs && !fetchedLogs" class="application-logs-fetch-status">
            <div>Fetching logs ...</div>
        </div>
        <div *ngIf="!fetchingLogs && fetchedLogs && !logs.length" class="application-logs-fetch-status">
            <div>No logs yet.</div>
        </div>
        <div class="application-log-repeater" *ngFor="let log of logs">
            <app-application-log [log]="log" [isFirstOfDay]="dayFirstLog[log.id] ? true : false"></app-application-log>
        </div>
    </div>
</div>
