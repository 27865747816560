import {
    Component,
    EventEmitter,
    Input,
    Optional,
    Output,
} from '@angular/core';

import { FormControlHolderDirective } from '../form-control-holder.directive';
import { FormControlDirective } from '../form-control.directive';

@Component({
    selector: 'bk-validation-display',
    templateUrl: './validation-display.component.html',
    styleUrls: ['./validation-display.component.scss']
})
export class ValidationDisplayComponent {
    @Input() control: FormControlDirective;
    classes: any = {};
    ctrl: FormControlDirective;
    didSetup: boolean = false;
    itemsArray: Array<any> = [];
    missingCtrl: boolean = false;
    @Output() updated = new EventEmitter<any>();

    constructor (
        @Optional() public bkFormControlHolder: FormControlHolderDirective
    ) {
        if (this.control) {
            this.setup(this.control);
        }
        else if (bkFormControlHolder) {
            if (bkFormControlHolder.bkFormControl) {
                this.setup(bkFormControlHolder.bkFormControl);
            }
            else {
                let didSetup = false;
                bkFormControlHolder.registeredFormControl.subscribe((bkFormControl: FormControlDirective) => {
                    this.setup(bkFormControl);
                    didSetup = true;
                });
                setTimeout(() => {
                    if (didSetup) return;
                    this.missingCtrl = true;
                    console.warn(`Validation display: Form control has not registered. Did you forget to add bkFormControl directive to associated input?`);
                }, 789);
            }
        }
    }

    handleClasses (): void {
        const ctrl = this.ctrl;
        this.classes.error = (ctrl.currentErrorKeys.length > 0);
        this.itemsArray.forEach((item) => {
            this.classes[`has-validation-error-key-${item.key}`] = (ctrl.currentErrorKeys.indexOf(item.key) > -1);
        });
    }

    makeItemsArray (): void {
        this.itemsArray =  this.ctrl.itemsArray.filter((item) => {
            const err = item.error || {};
            return err.error && !err.hint;
        });
    }

    setup (ctrl: FormControlDirective): void {
        if (this.didSetup) return;
        this.didSetup = true;
        this.ctrl = ctrl;
        ctrl.updated.subscribe(() => {
            this.makeItemsArray();
            this.handleClasses();
            this.updated.emit();
        });
        this.handleClasses();
    }
}
