import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { IIntegration } from '@amt/i-integration';

import { EnvService } from 'bkng';

import { IntegrationService } from '../integration.service';
import { CreateNewIntegrationDialogComponent } from '../create-new-integration-dialog/create-new-integration-dialog.component';
import { IExternalApplication } from '@amt/i-external-application';

@Component({
    selector: 'app-integrations',
    templateUrl: './integrations.component.html',
    styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {
    externalApplications: Array<IExternalApplication> = [];
    creatingNewIntegration: boolean = false;
    env: any;
    integrations: Array<IIntegration> = [];

    constructor (
        public route: ActivatedRoute,
        public envService: EnvService,
        public dialog: MatDialog,
        public integrationService: IntegrationService,
    ) {
        this.env = envService.data;
    }

    createNewIntegration (): void {
        this.creatingNewIntegration = true;

        const dialogRef = this.dialog.open(CreateNewIntegrationDialogComponent, {
            width: '250px',
            data: {
                externalApplications: this.externalApplications,
                integrations: this.integrations,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.creatingNewIntegration = false;
        });
    }

    ngOnInit (): void {
        this.integrationService.index().then((result) => {
            console.log(`Integrations got`, result);
            Object.assign(this, result);
        }).catch((err) => {
            console.error(err);
        });
    }

}
