import {
    Injectable
} from '@angular/core';

import { EnvService } from './env.service';

/*
(<any>window).recaptchaLoadedFn = () => {
    console.log('test 1');
}
*/

@Injectable({
    providedIn: 'root'
})
export class RecaptchaService {
    env: any;
    grecaptcha: any;
    sitekey: string;

    constructor (public envService: EnvService) {
        const env = this.env = envService.data;

        (<any>window).recaptchaLoadedFn = () => {
            // loaded
            this.grecaptcha = (<any>window).grecaptcha;

        }

        if (!this.sitekey && env.recaptcha && env.recaptcha.sitekey) {
            this.sitekey = env.recaptcha.sitekey;
            this.addScript(env.recaptcha.sitekey);
        }
    }

    addScript (key: string): void {
        const script = document.createElement('script');
        // const lang = this.lang ? '&hl=' + this.lang : '';
        script.src = `https://www.google.com/recaptcha/api.js?onload=recaptchaLoadedFn&render=${key}`;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }

    execute (options: any = {}): Promise<any> {
        if (!this.grecaptcha) {
            return Promise.reject(new Error(`Recaptcha not loaded.`));
        }

        // grecaptcha.execute( ...  does not return catch!
        return new Promise((resolve, reject) => {
            this.grecaptcha.execute(this.sitekey, options).then((token) => {
                resolve(token)
            });
        });
    }
}
