import { Component, OnInit } from '@angular/core';

import { EnvService } from 'bkng';

import { IEnv } from '@alt/i-env';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
    env: IEnv;

    constructor (
        public envService: EnvService,
    ) {
        this.env = envService.data;
    }

    ngOnInit (): void {
    }
}
