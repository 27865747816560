
<div class="sidebyeaches phone">
    <div class="diallingcode-input" bkFormControlHolder>
        <bk-select-dialling-codes
            [name]="name + 'cc'"
            [(ngModel)]="phone.country_code"
            [selectList]="env.countries"
            [disabled]="disabled"
        ></bk-select-dialling-codes>
    </div>
    <div class="phonenumber-input" bkFormControlHolder>
        <div class="input-label" *ngIf="!phone.value">{{label}}</div>
        <input
            bkFormControl
            [autocomplete]="'tel'"
            [type]="'tel'"
            [name]="name"
            [(ngModel)]="phone.value"
            (keyup.enter)="pressedEnter.emit()"
            [bkPhoneFilter]="phone.country_code"
            [required]="required"
            [ngClass]="{'disabled': disabled}"
            [disabled]="disabled"
        >
        <bk-validation-display></bk-validation-display>
    </div>
</div>
