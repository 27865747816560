import {
    Injectable
} from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import {
    Observable
} from 'rxjs';

import { EnvService } from './env.service';

@Injectable()
export class HttpXsrfInterceptor implements HttpInterceptor {
    env: {
        csrfToken: string;
    };

    constructor (
        public envService: EnvService
    ) {
        this.env = envService.data;
    }

    intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headerName = 'X-CSRF-Token';
        const csrfToken = this.env.csrfToken;
        if (
            (typeof(csrfToken) === 'string')
            &&
            !req.headers.has(headerName)
        ) {
            req = req.clone({ headers: req.headers.set(headerName, csrfToken) });
        }
        return next.handle(req);
    }
}
