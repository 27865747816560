<h1 mat-dialog-title>Create Integration</h1>
<div mat-dialog-content>
    <form #createNewIntegrationForm="ngForm" (ngSubmit)="create(newIntegration)">
        <mat-form-field appearance="fill" style="min-width: 150px;">  
            <mat-label>Application</mat-label>
            <mat-select [name]="'SelectedPipe'" [(ngModel)]="newIntegration.external_application_id">
                <mat-option *ngFor="let app of data.externalApplications" [value]="app.id">
                    <span>{{app.label}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <input matInput type="text" name="label" placeholder="e.g. RamRod Production" [(ngModel)]="newIntegration.label" required>
        </mat-form-field>
    </form>
</div>

<div mat-dialog-actions>
    <button mat-raised-button [color]="'primary'" (bkButton)="submit(createNewIntegrationForm)" [bkBusy]="creating">Create</button>
    <button mat-raised-button [color]="'basic'" (bkButton)="close()">Cancel</button>
</div>
