<!-- [style.padding-top]="paddingTop" -->
<div
    class="responsive-iframe-container"
    [style.width]="width"
    [style.height]="height"
>
    <iframe
        class="responsive-iframe"
        [src]="src | safe:'resourceUrl'"
        [attr.title]="title ? title : null"
        [attr.frameborder]="[undefined].includes(frameborder) ? null : frameborder"
        [attr.allow]="[undefined].includes(allow) ? null : allow"
        [attr.allowfullscreen]="[undefined].includes(allowfullscreen) ? null : allowfullscreen"
    ></iframe>   
</div>
