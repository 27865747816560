import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { EnvService } from 'bkng';

import { IApplication } from '@amt/i-application';
import { IApplicationLogSource } from '@amt/i-application-log-source';
import { IApplicationLogType } from '@amt/i-application-log-type';
import { IIntegration } from '@amt/i-integration';

import { ApplicationService } from '../application.service';

@Component({
    selector: 'app-application-settings',
    templateUrl: './application-settings.component.html',
    styleUrls: ['./application-settings.component.scss']
})
export class ApplicationSettingsComponent implements OnInit {
    changingSlackIntegration: boolean = false;
    clearingLogs: boolean = false;
    data: {
        application: IApplication;
        logSources: Array<IApplicationLogSource>;
        logTypes: Array<IApplicationLogType>;
        slackIntegrations: Array<IIntegration>;
    };
    deleteAppId: string = '';
    deletingApp: boolean = false;
    saveSubject: Subject<any> = new Subject();
    wantToClearLogs: boolean = false;
    wantToDelete: boolean = false;

    constructor (
        public route: ActivatedRoute,
        public router: Router,
        public envService: EnvService,
        public applicationService: ApplicationService,
    ) {
        this.saveSubject.pipe(
            debounceTime(500)
        ).subscribe(() => {
            this.save();
        });
    }

    changedSlackIntegration (): void {
        if (this.changingSlackIntegration) {
            return;
        }
        this.changingSlackIntegration = true;
        this.save().finally(() => {
            this.changingSlackIntegration = false;
        });
    }

    clearApplicationLogs (): void {
        if (!this.wantToClearLogs) {
            this.wantToClearLogs = true;
            return;
        }
        this.wantToClearLogs = false;
        this.clearingLogs = true;
        this.applicationService.clearLogsByAppId(this.data.application.id).then(() => {
            console.log(`Deleted logs: app id ${this.data.application.id}!`);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.clearingLogs = false;
        });
    }

    clearSlackIntegration (): void {
        delete this.data.application.data.slackIntegrationId;
        this.changedSlackIntegration();
    }

    deleteApplication (): void {
        if (!this.wantToDelete) {
            this.wantToDelete = true;
            return;
        }
        if (this.deleteAppId !== this.data.application.id) {
            return;
        }
        this.wantToDelete = false;
        this.deleteAppId = '';
        this.deletingApp = true;
        this.applicationService.deleteById(this.data.application.id).then(() => {
            console.log(`Deleted app ${this.data.application.id} !`);
            this.data.application.deleted = true;
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.deletingApp = false;
        });
    }

    ngOnInit (): void {
        this.applicationService.getById(this.route.parent.snapshot.params.applicationId).then((result) => {
            this.data = result;
            console.log(`Application:`, result);
        }).catch((err) => {
            console.error(err);
        });
    }

    save (): Promise<any> {
        return this.applicationService.updateApplication(this.data.application.id, this.data.application).then((response: any) => {
            console.log('Updated application:', response);
        }).catch((err) => {
            console.error(err);
        });
    }
}
