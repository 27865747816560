import { Component, Input, OnInit } from '@angular/core';

import { IIntegration } from '@amt/i-integration';

@Component({
    selector: 'app-integration-list-item',
    templateUrl: './integration-list-item.component.html',
    styleUrls: ['./integration-list-item.component.scss']
})
export class IntegrationListItemComponent implements OnInit {
    @Input() integration: IIntegration;
    @Input() preview: boolean = false;

    constructor () { }

    ngOnInit (): void {
    }

}
