<div *ngIf="fetched && !fetching">
    <mat-card *ngIf="env.user">
        <div style="padding-bottom: 15px;">Hello, {{env.user.name || env.user.email}}.</div>
        <!-- <div><mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon></div> -->
        <div>
            <button mat-raised-button color="primary" (click)="createApp()">Create application</button>
        </div>
    </mat-card>

    <div *ngFor="let app of applications" style="padding-top: 15px;">
        <app-list-item-application [application]="app"></app-list-item-application>
    </div>
</div>

<div *ngIf="fetching">
    <span>Fetching records ...</span>
</div>
