import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ApplicationSettingsComponent } from './application-settings/application-settings.component';
import { ApplicationViewLogsComponent } from './application-view-logs/application-view-logs.component';
import { ApplicationViewComponent } from './application-view/application-view.component';

import { HomeRouteComponent } from './home/home.component';
import { IntegrationViewComponent } from './integration-view/integration-view.component';
import { IntegrationsComponent } from './integrations/integrations.component';

const appRoutes: Routes = [
    {
        path: 'integrations/id/:integrationId',
        component: IntegrationViewComponent,
    },
    {
        path: 'integrations',
        component: IntegrationsComponent,
    },
    {
        path: 'application/id/:applicationId',
        component: ApplicationViewComponent,
        children: [
            {
                path: 'settings',
                component: ApplicationSettingsComponent,
            },
            {
                path: '',
                component: ApplicationViewLogsComponent,
            },
        ]
    },
    {
        path: '',
        component: HomeRouteComponent,
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(appRoutes, {
            // enableTracing: true,
            scrollPositionRestoration: 'enabled',
            relativeLinkResolution: 'legacy'
        }),
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule { }
