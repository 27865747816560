import {
    Component,
    Inject,
    OnInit,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import {
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { IExternalApplication } from '@amt/i-external-application';
import { IIntegration } from '@amt/i-integration';
import { IntegrationService } from '../integration.service';

export interface CreateNewIntegrationDialogData {
    externalApplications: Array<IExternalApplication>;
    integrations: Array<IIntegration>;
}

@Component({
    selector: 'app-create-new-integration-dialog',
    templateUrl: './create-new-integration-dialog.component.html',
    styleUrls: ['./create-new-integration-dialog.component.scss']
})
export class CreateNewIntegrationDialogComponent implements OnInit {
    creating: boolean = false;
    newIntegration: IIntegration = <any>{};

    constructor (
        public dialogRef: MatDialogRef<CreateNewIntegrationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CreateNewIntegrationDialogData,
        public integrationService: IntegrationService,
    ) {
        dialogRef.disableClose = true;
    }

    close (): void {
        this.dialogRef.close();
    }

    create (integration: any): Promise<any> {
        if (this.creating) return Promise.resolve();
        
        this.creating = true;

        return this.integrationService.createNew(integration).then((result) => {
            console.log('Created', result);
            this.data.integrations.unshift(result);
            this.close();
            return result;
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.creating = false;
        });
    }

    ngOnInit (): void {
    }

    submit (form: NgForm): void {
        form.ngSubmit.emit();
    }
}
