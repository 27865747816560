import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApplication } from '@amt/i-application';

import { EnvService, KCache, RestService } from 'bkng';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService extends RestService {
    constructor (http: HttpClient, envService: EnvService) {
        super(http, envService);
        this.namespace = 'application';
    }

    applicationByIdCache = new KCache((id) => {
        return this.$get(`id/${id}`).toPromise();
    }, (cached, result) => {
        cached[result.application.id] = result;
        return Promise.resolve();
    });

    clearLogsByAppId (applicationId: string): Promise<any> {
        return lastValueFrom(this.$delete(`id/${applicationId}/logs`));
    }

    createApplication (): Promise<any> {
        return lastValueFrom(this.$post(`create`));
    }

    deleteById (applicationId: string): Promise<any> {
        return lastValueFrom(this.$delete(`id/${applicationId}`));
    }

    get (params: any = {}): Promise<any> {
        return lastValueFrom(this.$get(``, {
            params
        }));
    }

    getApplicationLogs (applicationId: string, params: any = {}): Promise<any> {
        return lastValueFrom(this.$get(`id/${applicationId}/logs`, {
            params
        }));
    }

    getById (id: string): Promise<any> {
        return this.applicationByIdCache.get(id);
    }

    updateApplication (applicationId: string, application: IApplication): Promise<IApplication> {
        return lastValueFrom(this.$post(`id/${applicationId}/update`, application)).then((result: any) => {
            return result;
        });
    }
}
