import { Component, Input, OnInit } from '@angular/core';
import { IApplication } from '@amt/i-application';

@Component({
    selector: 'app-list-item-application',
    templateUrl: './list-item-application.component.html',
    styleUrls: ['./list-item-application.component.scss']
})
export class ListItemApplicationComponent implements OnInit {
    @Input() application: IApplication;
    
    constructor () { }

    ngOnInit (): void {
    }

}
