import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'friendlyDateTime',
    pure: false,
})
export class FriendlyDateTimePipe implements PipeTransform {
    transform (date: any): string {
        return moment(date).local().format('MMM Do, YYYY @ h:mm a');
    }
}
