import {
    Component,
    OnInit,
    Input
} from '@angular/core';

import { EnvService } from 'bkng';

@Component({
    selector: 'app-interface-sidenav',
    templateUrl: './interface-sidenav.component.html',
    styleUrls: ['./interface-sidenav.component.scss']
})
export class InterfaceSidenavComponent implements OnInit {
    env: any = {};
    @Input() drawer: any = {};

    constructor (
        public envService: EnvService,
    ) {
        this.env = envService.data;
    }

    dispatchWindowResizedEvent (): void {
        window.dispatchEvent(new Event('resize'));
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 55);
    }

    ngOnInit (): void {
    }
}
