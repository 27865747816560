import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'timeAgo',
    pure: false,
})
export class TimeAgoPipe implements PipeTransform {
    transform (date: any): string {
        return moment(date).local().fromNow();
    }
}
