import {
    HttpClient
} from '@angular/common/http';
import {
    Compiler,
    Injectable,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';

import * as utils from './utils';

@Injectable({
    providedIn: 'root'
})
export class EnvService {
    data: any = {};

    constructor (
        private http: HttpClient,
        private compiler: Compiler,
        private titleService: Title,
    ) {
        window.addEventListener('focus', (e: FocusEvent) => {
            this.get({action: 'windowfocus'});
        });
    }

    bootstrap (queryParams: {
        [key: string]: any;
    } = {}): Promise<any> {
        queryParams.bootstrap = 1;
        return this.get(queryParams);
    }

    get (params: any = {}): Promise<any> {
        const http = this.http;
        params.t = (new Date()).getTime();
        return lastValueFrom(http.get('/environment.json', {params})).then((resp) => {
            const env = this.data;
            const incomingEnvData: any = resp;

            if (incomingEnvData.revision && env.revision) {
                const incomingRevision = incomingEnvData.revision;
                const existingRevision = env.revision;
                let mismatch = false;
                if (incomingRevision.docker && existingRevision.docker && (incomingRevision.docker !== existingRevision.docker)) {
                    console.warn('Docker revision mismatch', env.revision, incomingEnvData.revision);
                    mismatch = true;
                }
                if (incomingRevision.git && existingRevision.git && (incomingRevision.git !== existingRevision.git)) {
                    console.warn('Git revision mismatch', env.revision, incomingEnvData.revision);
                    mismatch = true;
                }
                if (mismatch) {
                    this.compiler.clearCache();
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                    return resp;
                }
            }
            else if (!incomingEnvData.static && env.static) {
                console.warn('No longer static');
                setTimeout(() => {
                    window.location.reload();
                }, 500);
                return resp;
            }

            /*if (env.user && !incomingEnvData.user) {
                console.warn(`User logged-out.`);
                setTimeout(() => {
                    window.location.reload();
                }, 500);
                return resp;
            }*/

            if (env.user?.id && !incomingEnvData.user?.id) {
                console.warn(`EnvService: User logged-out.`);
                delete env.user; // trying to avoid "stale" logged-in state
            }

            // Object.assign(env, incomingEnvData);
            utils.updateObject(env, incomingEnvData);

            if (params.bootstrap && env.mode !== 'production') {
                console.log('Env', env);
            }

            if (params.bootstrap && env?.head?.title) {
                // might not be the best way to do this.
                this.titleService.setTitle(env.head.title);
            }

            return resp;
        }).catch((err) => {
            console.log('Env service error', err.message);
            console.error(err);
        });
    }
}
