<div class="integrations-container">
    <div class="buttons" style="padding: 25px 0px;">
        <button mat-raised-button class="button createnew" (bkButton)="createNewIntegration()" [bkBusy]="creatingNewIntegration">Create New Integration</button>
    </div>

    <div class="integrations-list" style="padding: 25px 0px;">
        <div *ngFor="let integration of integrations" style="padding: 15px 0px;">
            <app-integration-list-item [integration]="integration" [preview]="true"></app-integration-list-item>
        </div>
    </div>
</div>
