import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output
} from '@angular/core';

@Directive({
    selector: '[bkButton]'
})
export class ButtonDirective {
    @Input() bkBusy: boolean = false;
    @Output() bkButton = new EventEmitter<any>();

    constructor (
        public element: ElementRef
    ) {
        if ([null, undefined].indexOf(element.nativeElement.getAttribute('tabindex')) > -1) {
            element.nativeElement.setAttribute('tabindex', 0);
        }
    }

    @HostListener('click', ['$event'])
    clicked (event: any): void {
        if (this.bkBusy) {
            return;
        }
        this.bkButton.emit(event);
    }

    @HostListener('keydown.enter', ['$event'])
    onEnterHandler (event: KeyboardEvent): void {
        if (this.bkBusy) {
            return;
        }
        this.bkButton.emit(event);
    }
}
