import {
    ErrorHandler,
    Injectable,
} from '@angular/core';
import { lastValueFrom } from 'rxjs';

import {
    EnvService
} from './env.service';

import {
    RestService
} from './rest.service';

const errorsToReport = [];
let numberOfErrorReports = 0;
const maxErrorReports = 5;

@Injectable({
    providedIn: 'root'
})
export class BkErrorHandler implements ErrorHandler {
    env: any;
    reporting: boolean = false;

    constructor (
        public envService: EnvService,
        public restService: RestService,
    ) {
        this.env = envService.data;
        
        //if (this.env.logToServer) {
        //}
    }

    addErrorToReport (data: any): void {
        if (errorsToReport.length >= 5) {
            return; // buffer's full
        }
        errorsToReport.push(data);
    }

    handleError (error: any): void {
        let reportErrors = typeof(this.env.reportErrors) === 'undefined' ? true : (this.env.reportErrors ? true : false);

        if (numberOfErrorReports > maxErrorReports) {
            reportErrors = false;
        }

        if (!reportErrors) {
            return;
        }

        if (
            this.env.mode === 'production'
            &&
            `${error?.message}`.trim().toLowerCase().includes(`cannot match any routes`)
        ) {
            // perhaps there should be a better way of handling this.
            console.warn(error);
            return;
        }

        const data: any = {
            errorVariableType: Object.prototype.toString.call(error),
            href: window.location.href,
        };

        if ([
            `[object Error]`,
            `[object Object]`
        ].indexOf(Object.prototype.toString.call(error)) > -1) {
            try {
                data.string = JSON.stringify(error, Object.getOwnPropertyNames(error));
            }
            catch (e) {
                data.string = `Could not stringify ${Object.prototype.toString.call(error)} ${e?.message}`;
            }
        }
        else if ([
            `[object Array]`
        ].indexOf(Object.prototype.toString.call(error)) > -1) {
            try {
                data.string = JSON.stringify(error, Object.getOwnPropertyNames(error));
            }
            catch (e) {
                data.string = `Could not stringify ${Object.prototype.toString.call(error)} ${e?.message}`;
            }
        }
        else if (typeof(error) === 'string') {
            data.string = error;
        }
        else {
            data.raw = error;
        }

        if (error?.message) {
            data.message = error.message;
        }

        this.addErrorToReport(data);

        this.startReportingErrors();

        console.error(error);
    }

    logToServer (...args: any[]): Promise<any> {
        if (this.env?.api?.pathPrefix && (this.env !== args[1])) {
            const txt = JSON.stringify(args, null, 4);
            return this.restService.$post(`report_log`, {jsonStr: txt}).toPromise().then(() => {
                // local.CL("Reported.");
            }).catch((e) => {
                console.error("Did not report", e);
            });
        }
    }

    reportErrors (): Promise<any> {
        if (numberOfErrorReports > maxErrorReports) {
            return Promise.resolve();
        }
        
        return lastValueFrom(this.restService.$post(`/report_error`, errorsToReport.splice(0, errorsToReport.length), {responseType: 'text'})).catch((err) => {
            console.error("There was a problem when attempting to report errors", err);
        }).finally(() => {
            numberOfErrorReports++;
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(null);
                }, (this.env.mode === 'production' ? 5000 : 500) * numberOfErrorReports);
            }).then(() => {
                if (errorsToReport.length) {
                    return this.reportErrors();
                }
                this.reporting = false;
                return Promise.resolve();
            });
        });        
    }

    startReportingErrors (): void {
        if (this.reporting) {
            return;
        }
        this.reporting = true;
        this.reportErrors().finally(() => {
            this.reporting = false;
        });
    }
}
