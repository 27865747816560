declare const Stripe: any;

import {
    Injectable
} from '@angular/core';

import { EnvService } from './env.service';

@Injectable({
    providedIn: 'root'
})
export class StripeService {
    env: any;
    stripe: any;

    constructor (
        public envService: EnvService
    ) {
        const env = this.env = envService.data;

        if (env.stripe?.key) {
            if (typeof(Stripe) !== 'undefined') {
                this.stripe = Stripe(env.stripe.key);
            }
            else {
                this.stripe = {
                    elements: () => {
                        return {
                            create: () => {
                                return {
                                    mount: () => {
                                        return {};
                                    },
                                    on: () => {
                                        return {};
                                    }
                                }
                            }
                        }
                    },
                    paymentRequest: () => {
                        return {
                            canMakePayment: () => {
                                return Promise.resolve({});
                            },
                            on: () => {
                                return {};
                            }
                        }
                    }
                }
            }
        }
    }
}
