

export function updateObject <TARGET, SOURCE, SENTIMENTAL = boolean> (objectToUpdate: TARGET, objectToUpdateFrom: SOURCE, sentimental: SENTIMENTAL = <any>false): SENTIMENTAL extends true ? (TARGET & SOURCE) : SOURCE {
    const _updateObject = (_obj, _uObj, _lineage?) => {
        _lineage = [].concat(_lineage || []);

        if (_lineage.indexOf(_uObj) > -1) {
            // Don't dive into a circular reference.
            return;
        }

        _lineage.push(_uObj);

        if (!sentimental) {
            if (Object.prototype.toString.call(_uObj) == '[object Object]') {
                const uObjKeys = Object.getOwnPropertyNames(_uObj); //was Object.keys ...
                for (const key in _obj) {
                    if (uObjKeys.indexOf(key) < 0) {
                        delete _obj[key];
                    }
                }
            }
        }

        for (const uKey of Object.getOwnPropertyNames(_uObj)) {
            if (Object.prototype.toString.call(_uObj[uKey]) == '[object Object]') {
                if (Object.prototype.toString.call(_obj[uKey]) != '[object Object]') {
                    _obj[uKey] = {};
                }
                if (_lineage.indexOf(_uObj[uKey]) > -1) {
                    console.error(`[updateObject] (Depth: ${_lineage.length}) Key '${uKey}' is circular.`);
                    return;
                }
                _updateObject(_obj[uKey], _uObj[uKey], _lineage);
            }
            else if (Object.prototype.toString.call(_uObj[uKey]) == '[object Array]') {
                if (Object.prototype.toString.call(_obj[uKey]) != '[object Array]') {
                    _obj[uKey] = [];
                }
                _updateObject(_obj[uKey], _uObj[uKey], _lineage);
            }
            else {
                _obj[uKey] = _uObj[uKey];
            }
        }

        return _obj;
    }

    return _updateObject(objectToUpdate, objectToUpdateFrom);
}
