<div class="log-filters-component">
    <div>
        <mat-form-field>  
            <mat-label>Class</mat-label>
            <mat-select [name]="'FilterClass'" [(ngModel)]="filters.class" (ngModelChange)="updateFilters()">
                <mat-option *ngFor="let lc of data.logClasses" [value]="lc.id">
                    <span>{{lc.label}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <mat-label>Since</mat-label>
            <input matInput [name]="'FilterSince'" type="datetime-local" placeholder="Since" [(ngModel)]="filters.since" (blur)="updateFilters()" [value]="filters.since">
        </mat-form-field>
    </div>
</div>
