<div class="login-page">
    <div class="logo"></div>
    <div class="login-top-spacer"></div>
    <div class="login-inner">
        <div class="title">Welcome</div>
        <div class="text">Use your account to login</div>
        <div class="login-button" *ngIf="env.auth?.methods?.google">
            <a mat-flat-button color="primary" href="/auth/user/google">Google</a>
        </div>
        <div class="login-button" *ngIf="env.auth?.methods?.azAd">
            <a mat-flat-button color="primary" href="/auth/user/azuread-openidconnect">Azure AD</a>
        </div>
    </div>
    <div class="login-bottom-spacer"></div>
</div>
