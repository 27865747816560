import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'logDate',
    // pure: false,
})
export class LogDatePipe implements PipeTransform {
    transform (date: any): string {
        return moment(date).local().format('YYYY/MM/DD');
    }
}
