import {
    Component,
    ElementRef,
    forwardRef,
    Renderer2,
} from '@angular/core';

import {
    ControlContainer,
    NG_VALUE_ACCESSOR
} from '@angular/forms';


import { ContentService } from '../content.service';
import {
    SelectComponent,
    ISelectListItem
} from '../select/select.component';

@Component({
    selector: 'bk-select-countries',
    templateUrl: './select-countries.component.html',
    styleUrls: ['./select-countries.component.scss'],
    exportAs: 'SelectComponent',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectCountriesComponent),
            multi: true
        }
    ],
})
export class SelectCountriesComponent extends SelectComponent {
    primaryKey = 'code';

    constructor (
        element: ElementRef,
        renderer: Renderer2,
        CS: ContentService,
        controlContainer: ControlContainer,
    ) {
        super(
            element,
            renderer,
            CS,
            controlContainer
        );
    }

    display (item: ISelectListItem): string {
        return `${item?.data?.name || ''}`;
    }

    getItemValue (item: ISelectListItem): string {
        return item.data.code;
    }

    onCountryAutofill (): void {
        const inputEvent = (<InputEvent>event);
        const val = (<any>inputEvent.srcElement).value;
        this.selectItemByKey(val);
    }
}
