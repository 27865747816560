import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { lastValueFrom } from 'rxjs';

import {
    EnvService,
    KCache,
    RestService
} from 'bkng';

import { IIntegration } from '@amt/i-integration';

@Injectable({
    providedIn: 'root'
})
export class IntegrationService extends RestService {
    constructor (http: HttpClient, envService: EnvService) {
        super(http, envService);
        this.namespace = `integration`;
    }

    createNew (fromObject: any): Promise<any> {
        return lastValueFrom(this.$post(`create_new`, fromObject));
    }

    getById (integrationId: string): Promise<any> {
        return lastValueFrom(this.$get(`id/${integrationId}`));
    }

    getRepositoryBranches (integrationId: string, repositoryId: string | number): Promise<any> {
        return lastValueFrom(this.$get(`id/${integrationId}/repository/id/${repositoryId}/branches`));
    }

    getSlackChannels (integrationId: string): Promise<any> {
        return this.slackChannelsCache.get(integrationId);
    }

    index (): Promise<any> {
        return lastValueFrom(this.$get(``));
    }

    save (integration: IIntegration): Promise<IIntegration> {
        return lastValueFrom(this.$post(`id/${integration.id}/save`, integration)).then((result) => {
            return <any>result;
        });
    }

    slackChannelsCache = new KCache((integrationIds) => {
        return lastValueFrom(this.$get(`/slack/channels`, {
            params: {
                integration_ids: integrationIds.join(',')
            }
        }));
    }, (cached, result) => {
        result.slacks.forEach((item) => {
            cached[item.id] = item;
        });
        return Promise.resolve();
    });

    
}
