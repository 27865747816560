import { HttpClient } from '@angular/common/http';
import {
    Injectable,
} from '@angular/core';
import { Observable, share } from 'rxjs';
import { EnvService } from './env.service';

@Injectable({
    providedIn: 'root'
})

export class RestService {
    env: any;
    namespace = '';

    constructor (
        public http: HttpClient,
        public envService: EnvService
    ) {
        this.env = envService.data;
    }
    
    $getPrefix (): string {
        let prefix = ``;
        if (this.env?.api) {
            if (this.env.api.pathPrefix) {
                prefix = this.env.api.pathPrefix;
            }
        }
        return prefix;
    }
    
    $getUrl (url: string = ''): string {
        url = url || '';
        let urlToReturn = this.$getPrefix();

        if (this.namespace) {
            if (!/\/$/.test(urlToReturn) && !/^\//.test(this.namespace)) {
                urlToReturn = `${urlToReturn}/`;
            }
            urlToReturn = `${urlToReturn}${this.namespace}`;
        }

        if (url) {
            if (!/\/$/.test(urlToReturn) && !/^\//.test(url)) {
                urlToReturn = `${urlToReturn}/`;
            }
            urlToReturn = `${urlToReturn}${url}`;
        }

        if (!/^\//.test(urlToReturn)) {
            urlToReturn = `/${urlToReturn}`;
        }

        return urlToReturn;
    }
    
    $get (url: string = '', options: any = {}): Observable<ArrayBuffer> {
        const http = this.http;

        url = this.$getUrl(url);

        return this.$watch(http.get(url, options));
    }
    
    $delete (url: string = '', options: any = {}): Observable<ArrayBuffer> {
        const http = this.http;

        url = this.$getUrl(url);

        return this.$watch(http.delete(url, options));
    }
    
    $post (url: string = '', body: any = {}, options: any = {}): Observable<ArrayBuffer> {
        const http = this.http;

        url = this.$getUrl(url);

        return this.$watch(http.post(url, body, options));
    }

    $put (url: string = '', body: any = {}, options: any = {}): Observable<ArrayBuffer> {
        const http = this.http;

        url = this.$getUrl(url);

        return this.$watch(http.put(url, body, options));
    }

    $watch (request: Observable<ArrayBuffer>): Observable<ArrayBuffer> {
        request = request.pipe(share()); // avoid duplicate calls due to subscribe below. (Subscribe causes dupe. Don't fully understand yet.)
        
        request.subscribe({
            error: (err) => {
                if (err?.status) {
                    if ([
                        401,
                        403,
                        405,
                    ].includes(err.status)) {
                        return this.envService.get({action: `refresh-after-rest-error-${err.status}`}).catch((err) => {
                            console.warn(`Could not refresh environment.json after rest error ${err.status}.`);
                        });
                    }
                }
            },
        }); // next, complete also available

        return request;
    }
}
