<div *ngIf="fetching">
    <span>Fetching ...</span>
</div>
<div class="integration-view-container" *ngIf="!fetching && integration?.id">
    <p>{{integration.label || integration.id}}</p>

    <div class="input-holder">
        <input type="text" placeholder="nickname" [(ngModel)]="integration.label" (ngModelChange)="debounceSave()" />
    </div>

    <div style="padding-top: 55px;">
        <a [href]="'/auth/integration/id/' + integration.id + '/connect'">{{integration.data?.connected ? 'Reconnect' : 'Connect'}}</a>
    </div>
</div>
