<div *ngIf="data?.application?.id" class="application-view-container">
    <div class="application-view-app-title">
        <span *ngIf="data.application.deleted">DELETED: </span>
        <span>{{data.application.label || '(No label)'}}</span>
    </div>

    <div class="application-view-top-nav" *ngIf="!data.application.deleted">
        <a class="application-view-top-nav-item" [routerLink]="['.']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
            <span>Logs</span>
        </a>

        <a class="application-view-top-nav-item" [routerLink]="['.', 'settings']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{}">
            <span>Settings</span>
        </a>

        <div class="spacer"></div>

        <div *ngIf="showLogFilters()">
            <app-log-filters [data]="data"></app-log-filters>
        </div>
    </div>

    <div class="application-view-router" [ngClass]="routerClass" *ngIf="!data.application.deleted">
        <router-outlet></router-outlet>
    </div>
</div>
