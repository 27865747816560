import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'logTime',
    // pure: false,
})
export class LogTimePipe implements PipeTransform {
    transform (date: any): string {
        return moment(date).local().format('HH:mm:ss.SSS');
    }
}
