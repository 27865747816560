import { Component, OnInit } from '@angular/core';
import { EnvService } from 'bkng';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    env: any;
    title: string;

    constructor (private envService: EnvService) {
        const self = this;
        const env = self.env = envService.data;
        if (env.head) {
            self.title = env.head.title;
        }
    }

    ngOnInit (): void {
    }
}
