import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    Renderer2,
    OnInit,
    Optional,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    NG_VALIDATORS,
    Validator,
    ValidationErrors
} from '@angular/forms';

import { EnvService } from './env.service';
import { FormControlHolderDirective } from './form-control-holder.directive';
import { FormDirective } from './form.directive';

export interface IFormControlValidatorRequirement {
    error: string;
    fn? (val): boolean;
    regexp?: RegExp | string;
    REGEX?: RegExp;
}

export interface IFormControlValidatorRequirements {
    [name: string]: IFormControlValidatorRequirement
}

export interface IFormControlValidator {
    nullable?: boolean;
    requirements?: IFormControlValidatorRequirements;
    type?: string;
}

@Directive({
    selector: '[bkFormControl]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: FormControlDirective,
            multi: true
        }
    ],
    exportAs: `bkFormControl`
})
export class FormControlDirective implements Validator, OnInit {
    appliedValidators: AbstractControl;
    @Input('bkFormControl') config: IFormControlValidator;
    control: AbstractControl;
    currentErrorKeys: Array<string> = [];
    env: any;
    inputType: string;
    itemsObject: any = {};
    itemsArray: Array<any> = [];
    @Output() updated = new EventEmitter<any>();
    @Input() validatorName: string;

    constructor (
        private bkForm: FormDirective,
        public element: ElementRef,
        private renderer: Renderer2,
        private envService: EnvService,
        @Optional() public bkFormControlHolder: FormControlHolderDirective,
    ) {
        this.env = envService.data;
    }

    getError (key: string, error: any): any {
        if (typeof(error) !== 'object') {
            if (key == 'required') {
                return {
                    error: 'Required'
                }
            }
        }
        return error;
    }

    handleClasses (): void {
        setTimeout(() => {
            const ctrlErrors = this.control.errors || {};

            if (Object.keys(ctrlErrors).length) {
                this.renderer.addClass(this.element.nativeElement, 'error');
            }
            else {
                this.renderer.removeClass(this.element.nativeElement, 'error');
            }

            this.currentErrorKeys = Object.keys(ctrlErrors);

            this.currentErrorKeys.forEach((k) => {
                const err: any = ctrlErrors[k];
                if (this.itemsObject[k]) {
                    const item = this.itemsObject[k];
                    item.error = this.getError(k, err);
                }
                else {
                    const item = this.itemsObject[k] = {
                        key: k,
                        error: this.getError(k, err)
                    };
                    this.itemsArray.push(item);
                }
            });

            this.itemsArray.forEach((item) => {
                item.active = (this.currentErrorKeys.indexOf(item.key) > -1);
                if (item.active) item.hasActivated = true;
            });

            this.updated.emit();
        });
    }

    ngOnInit (): void {
        const bkForm = this.bkForm;
        const env = this.env;

        bkForm.bkFormUpdated.subscribe(() => {
            if (this.control) {
                // better way to do this?
                this.control.updateValueAndValidity();
            }
        });

        this.inputType = this.config ? this.config.type : (this.validatorName || this.element.nativeElement.getAttribute('type')); // confusing: inputType vs config.type !

        if (!this.config && env && env && env.validators && env.validators[this.inputType]) {
            this.config = env.validators[this.inputType];
        }

        if (this.bkFormControlHolder) {
            this.bkFormControlHolder.registerFormControl(this);
        }

        bkForm.registerFormControl(this);
    }

    testRequirements (control: AbstractControl, reqs: IFormControlValidatorRequirements, errs: any, invertRegexResult: boolean = false): void {
        Object.keys(reqs).forEach((k) => {
            const req = reqs[k];
            let requirementMet = true;
            if (req.regexp) {
                if (([null, undefined].indexOf(control.value) < 0) || this.config.nullable) {
                    req.REGEX = req.REGEX || ((typeof(req.regexp) === `string`) ? (new RegExp(req.regexp)) : req.regexp);
                    const regexResult = req.REGEX.test(control.value);
                    requirementMet = invertRegexResult ? !regexResult : regexResult; // not sure if invertRegexResult is the right way to go, but anyway...
                }
            }
            else if (req.fn) {
                requirementMet = req.fn(control.value);
            }
            if (!requirementMet) {
                errs[k] = req;
            }
        });
    }

    validate (control: AbstractControl): ValidationErrors | null {
        if (!this.control) {
            this.control = control;
            if (this.bkFormControlHolder) this.bkFormControlHolder.control = control;
        }

        const errs: any = {};

        if (this.config && this.config.requirements) {
            this.testRequirements(control, this.config.requirements, errs);
        }

        if (this.bkForm.serverFormErrors && this.bkForm.serverFormErrors[this.inputType]) {
            this.testRequirements(control, this.bkForm.serverFormErrors[this.inputType], errs, true);
        }

        this.handleClasses();

        return errs;
    }
}
