import { Injectable } from '@angular/core';
import { Router, Routes, Route } from '@angular/router';
import { Subject } from 'rxjs';

import { updateObject } from '../lib/utils';
import { ISetBkRouteDataOptions } from './i-set-bk-route-data-options';
export { ISetBkRouteDataOptions } from './i-set-bk-route-data-options';


export interface IBkRouteInfo {
    crumb?: string;
}

export interface IBkRoute {
    children?: Array<IBkRoute>;
    data?: any;
    info?: IBkRouteInfo;
    label?: string;
    ngRoute?: Route;
    parent?: IBkRoute;
    path?: string;
    propagate?: boolean;
    resolvedPath?: string;
    setInfo?: (data: any) => IBkRouteInfo;
}

export type IBkRoutes = Array<IBkRoute>;

@Injectable({
    providedIn: 'root'
})
export class RoutesService {
    ngRoutesToLoadByPath: {
        [path: string]: Route;
    } = {};
    ngRoutesToLoad: Routes = [];
    onData: Subject<ISetBkRouteDataOptions> = new Subject();

    constructor (
       public router: Router,
    ) {
    }

    comparePaths (path: string): number {
        const parts = path.split("/");

        let score = 0;

        parts.forEach((part, partIndex) => {
            if (part === '**') {
                // wildcard, super broad
                score = score - 1;
                return;
            }

            if (part.length > 0) {
                score = score + 1;
            }
        });

        return score;
    }

    getSortedPaths (unsortedPaths: Array<string>): Array<string> {
        const sortedPaths = unsortedPaths.sort((a, b) => {
            const partsA = a.split("/");
            const partsB = b.split("/");

            if (partsA.length > partsB.length) {
                return -1;
            }

            if (partsA.length < partsB.length) {
                return 1;
            }

            let returnComparisonValue = 0;

            for (let i = 0; i < partsA.length; i++) {
                const partA = partsA[i];
                const partB = partsB[i];

                let partComparisonValue = partA.localeCompare(partB);

                if ([partA, partB].includes("**")) {
                    partComparisonValue = partA === "**" ? -1 : 1;
                }

                if (partComparisonValue > 0) {
                    returnComparisonValue = -1;
                    break;
                }

                if (partComparisonValue < 0) {
                    returnComparisonValue = 1;
                    break;
                }
            }

            return returnComparisonValue;
        });

        return sortedPaths;
    }

    gobble (routes: IBkRoutes): Promise<Routes> {
        this.munchBkRoutes(routes);
        const routePaths = this.getSortedPaths(Object.keys(this.ngRoutesToLoadByPath));
        routePaths.forEach((p) => {
            this.ngRoutesToLoad.push(this.ngRoutesToLoadByPath[p]);
        });
        // console.log("ngRoutesToLoad", this.ngRoutesToLoad);
        this.router.resetConfig(this.ngRoutesToLoad);
        return Promise.resolve(this.ngRoutesToLoad);
    }

    loadNgRoute (ngRoute: Route): void {
        if (this.ngRoutesToLoadByPath[ngRoute.path]) {
            this.mergeNgRoutes(this.ngRoutesToLoadByPath[ngRoute.path], ngRoute);
        }
        else {
            this.ngRoutesToLoadByPath[ngRoute.path] = ngRoute;
        }
    }

    mergeNgRoutes (targetNgRoute: Route, sourceNgRoute: Route): void {
        if (sourceNgRoute.children?.length) {
            targetNgRoute.children = targetNgRoute.children || [];
            sourceNgRoute.children.forEach((sourceChild) => {
                const targetChild = targetNgRoute.children.filter((child) => {
                    return (
                        child.path === sourceChild.path
                        &&
                        child.outlet === sourceChild.outlet
                    );
                })[0];
                if (targetChild) {
                    this.mergeNgRoutes(targetChild, sourceChild);
                }
                else {
                    targetNgRoute.children.push(updateObject({}, sourceChild));
                }
            });
        }
    }

    munchBkRoute (bkRoute: IBkRoute, parent?: IBkRoute): IBkRoute {
        if (parent) {
            bkRoute.parent = parent;
            (parent.children || []).forEach((sibling) => {
                if (
                    (bkRoute !== sibling)
                    &&
                    sibling.propagate
                    &&
                    bkRoute.ngRoute
                ) {
                    const siblingCopy = updateObject({}, sibling);
                    bkRoute.children = bkRoute.children || [];
                    bkRoute.children.push(siblingCopy);
                }
            });
        }

        bkRoute.path = bkRoute.path || "";
        bkRoute.resolvedPath = `${bkRoute.path || ''}`;
        if (parent?.resolvedPath) {
            bkRoute.resolvedPath = `${parent.resolvedPath}${bkRoute.resolvedPath.length ? '/' : ''}${bkRoute.resolvedPath}`;
        }

        if (bkRoute.ngRoute) {
            const ngRoute: Route = updateObject({}, bkRoute.ngRoute);
            this.munchNgRoute(ngRoute);
            ngRoute.path = bkRoute.ngRoute.path = bkRoute.resolvedPath;
            this.loadNgRoute(ngRoute);
            ngRoute.data = bkRoute.ngRoute.data || {};
            ngRoute.data.bkRoute = bkRoute;
        }

        if (bkRoute.children?.length) {
            this.munchBkRoutes(bkRoute.children, bkRoute);
        }

        return bkRoute;
    }

    munchBkRoutes (routes: Array<IBkRoute>, parent?: IBkRoute): any {
        routes.forEach((route) => {
            this.munchBkRoute(route, parent);
        });
    }

    munchNgRoute (ngRoute: Route): Route {
        if (ngRoute.children) {
            ngRoute.children.forEach((child) => {
                this.munchNgRoute(child);
            });
        }
        ngRoute.path = ngRoute.path || "";
        return ngRoute;
    }

    setBkRouteData (options: ISetBkRouteDataOptions): void {
        if (!options.bkRoute) {
            throw new Error(`Must pass bkRoute to setBkRouteData`);
        }
        this.setBkRouteInfo(options);
        this.onData.next(options);
    }

    setBkRouteInfo (options: ISetBkRouteDataOptions): void {
        if (!options.bkRoute) {
            throw new Error(`Must pass bkRoute to setBkRouteInfo`);
        }

        if (options.bkRoute.setInfo) {
            options.bkRoute.info = options.bkRoute.setInfo(options.data);
        }

        if (options.bkRoute.parent) {
            const nextSetInfoOptions = Object.assign({}, options);
            nextSetInfoOptions.bkRoute = options.bkRoute.parent;
            this.setBkRouteInfo(nextSetInfoOptions);
        }
    }
}
