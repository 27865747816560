import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-application-view-logs',
    templateUrl: './application-view-logs.component.html',
    styleUrls: ['./application-view-logs.component.scss']
})
export class ApplicationViewLogsComponent implements OnInit {
    constructor (
        public route: ActivatedRoute,
    ) { }

    ngOnInit (): void {
    }

}
