import {
    Directive,
    ElementRef,
    forwardRef,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR
} from '@angular/forms';

import {
    AsYouType,
    CountryCode,
    parsePhoneNumber
} from 'libphonenumber-js/max';

import {
    PfDifference,
    PfFiltered,
    PfKeyDownEvent,
} from './phone-filter.interfaces';

@Directive({
    selector: '[bkPhoneFilter]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneFilterDirective),
            multi: true
        }
    ],
})
export class PhoneFilterDirective implements ControlValueAccessor, OnChanges, OnInit {
    @Input() bkPhoneFilter: CountryCode;
    element: any;
    filterId: number = 0;
    lastFiltered: PfFiltered;
    @Input('ngModel') model: any;
    numReg: RegExp = /\d+/g;
    onChange;
    onTouched;
    pfKeyDownEvent: PfKeyDownEvent;

    constructor (
        public elementRef: ElementRef
    ) {
        this.element = elementRef.nativeElement;
    }

    checkAndSetCaretPos (filtered: PfFiltered, inputEvent: InputEvent): void {
        if (
            (filtered.input.value != filtered.output.value)
            && filtered.pfKeyDownEvent?.caret
            && !filtered.pfKeyDownEvent.caret.atEnd
            && (
                ([8, 46].indexOf(filtered.pfKeyDownEvent.event.which) < 0) || filtered.becameValid
            )
        ) {
            const toLeft = filtered?.pfKeyDownEvent?.caret?.toLeft || '';
            const viewVal = this.element.value;

            if (
                filtered?.pfKeyDownEvent?.caret.position
                && inputEvent.data?.length
                && (toLeft === viewVal.substring(0, filtered.pfKeyDownEvent.caret.position))
            ) {
                // everything to the left of the caret before the input has stayed the same, and we have an input length, so just set the caret position to that.
                this.setCaretPosition(filtered.pfKeyDownEvent.caret.position + inputEvent.data.length);
            }
            else {
                const newData = inputEvent.data || '';
                const newStr = (toLeft + newData).substring(0, filtered.pfKeyDownEvent.caret.positionAfter);

                let numsToChew = ((newStr).match(this.numReg) || []).join('');
                
                let consumed = 0;
                
                let _viewVal = viewVal;
                while (numsToChew.length) {
                    const num = numsToChew.substr(0, 1);
                    numsToChew = numsToChew.substring(1, numsToChew.length);
                    const munch = _viewVal.indexOf(num) + 1;
                    _viewVal = _viewVal.substring(munch, _viewVal.length);
                    consumed += munch;
                }

                let goTo = consumed;

                let toAdd = 0;
                if (inputEvent.data?.length && (inputEvent.data.search(/\d/) < 0)) {
                    toAdd = inputEvent.data.length
                }
                
                goTo += toAdd;

                this.setCaretPosition(goTo);
            }
        }
    }

    filter (inputValue: string, countryCode: CountryCode): PfFiltered { // PfFiltered
        this.filterId++;

        const outputData: PfFiltered = {
            becameValid: false,
            countryCode,
            id: this.filterId,
            input: {value: inputValue},
            output: {value: inputValue},
            previous: this.lastFiltered,
            valid: false
        }

        if (this.pfKeyDownEvent) {
            outputData.pfKeyDownEvent = this.pfKeyDownEvent;
        }
        
        if (countryCode) {
            const asYouType = new AsYouType(countryCode);
            const asYouTypeVal = asYouType.input(inputValue);
            outputData.output.value = asYouTypeVal || inputValue; // don't let it be nothing.
            outputData.template = asYouType.getTemplate();
            outputData.number = asYouType.getNumber()?.number;               
        }

        try {
            if (outputData.number) {
                const phoneNumber = parsePhoneNumber(<string>outputData.number, countryCode);
                outputData.valid = phoneNumber.isValid();
                if (outputData.valid) {
                    outputData.output.value = phoneNumber.formatNational();
                    if (!this.lastFiltered?.valid) {
                        outputData.becameValid = true
                    }
                }
            }
        }
        catch (e) {
            outputData.valid = false
        }

        outputData.difference = this.getDifference(inputValue, this.lastFiltered?.input.value);

        this.lastFiltered = outputData;

        return outputData;
    }

    getDifference (newVal: string = '', oldVal: string = ''): PfDifference {
        const data: PfDifference = {
            start: this.getSimilarStart(newVal, oldVal),
            end: this.getSimilarEnd(newVal, oldVal)
        }

        let longestString = newVal;

        if (newVal.length < oldVal.length) {
            longestString = oldVal;
        }

        if (newVal == oldVal) {
            data.value = ''
        }
        else {
            data.value = longestString.substring(data.start.endsAt, longestString.length - data.end.lastMatch.length)
        }

        return data
    }

    getSimilarStart (newVal: string, oldVal: string): {
        newVal: string;
        oldVal: string;
        startsAt: number;
        endsAt: number;
        lastMatch: string;
    } {
        let lastMatch = '';
        let lastMatchIndex = 0
        
        const _munch = (index) => {
            const newCompare = newVal.substring(0, index);
            const oldCompare = oldVal.substring(0, index);
            
            if (newCompare == oldCompare) {
                lastMatch = newCompare;
                lastMatchIndex = index;
                if ((index + 1) <= newVal.length) {
                    return _munch(index + 1);
                }
            }

            return lastMatchIndex
        }

        return {
            newVal,
            oldVal,
            startsAt: 0,
            endsAt: _munch(0),
            lastMatch
        };
    }

    getSimilarEnd (newVal: string, oldVal: string): {
        newVal: string;
        oldVal: string;
        startsAt: number;
        endsAt: number;
        lastMatch: string;
    } {
        let lastMatch = '';
        let lastMatchIndex = 0;
        const _munch = (index) => {
            const newCompare = newVal.substring(index, newVal.length);
            const oldCompare = oldVal.substring(oldVal.length - (newVal.length - index), oldVal.length);
            
            if (newCompare == oldCompare) {
                lastMatch = newCompare
                lastMatchIndex = index
                if ((index - 1) >= 0) {
                    return _munch(index - 1);
                }
            }

            return lastMatchIndex
        }

        return {
            newVal,
            oldVal,
            startsAt: _munch(newVal.length),
            endsAt: newVal.length,
            lastMatch
        };
    }

    ngOnChanges (changes: SimpleChanges): void {
        if (changes.bkPhoneFilter) {
            if (changes.bkPhoneFilter.previousValue != changes.bkPhoneFilter.currentValue) {
                setTimeout(() => {
                    this.update();
                });
            }
        }
    }

    ngOnInit (): void {
        
    }

    @HostListener('blur', ['$event'])
    onBlurHandler (e: FocusEvent): void {
        this.update();
    }

    @HostListener('input', ['$event'])
    onInputHandler (e: InputEvent): void {
        const filtered = this.update();
        this.checkAndSetCaretPos(filtered, e);
    }

    @HostListener('keydown', ['$event'])
    onKeyDownHandler (event: KeyboardEvent): void {
        const elementValue = this.element.value;
        
        const selectionStart = this.element.selectionStart;
        const selectionEnd = this.element.selectionEnd;
        const selectionValue = elementValue.substring(selectionStart, selectionEnd);

        let selectionStartPlusOne = selectionStart + 1;

        if ([8].indexOf(event.which) > -1) {
            if (selectionValue.length) {
                selectionStartPlusOne = selectionStart;
            }
            else {
                selectionStartPlusOne = selectionStart - 1;
            }
        }
        else if ([46].indexOf(event.which) > -1) {
            selectionStartPlusOne = selectionStart;
        }

        const ev: PfKeyDownEvent = {
            caret: {
                atEnd: selectionStart == elementValue.length,
                charLeft: elementValue.substr(selectionStart - 1, 1),
                charRight: elementValue.substr(selectionStart, 1),
                position: selectionStart,
                positionAfter: selectionStartPlusOne,
                toLeft: elementValue.substring(0, selectionStart),
                toRight: elementValue.substring(selectionEnd, elementValue.length),
            },
            elementValue,
            event,
            selection: {
                start: selectionStart,
                end: selectionEnd,
                value: selectionValue
            }
        };

        this.pfKeyDownEvent = ev;
    }

    registerOnChange (fn: any): void {
        // ng ControlValueAccessor
        this.onChange = fn;
    }

    registerOnTouched (fn: any): void {
        // ng ControlValueAccessor
        this.onTouched = fn;
    }

    setCaretPosition (pos: number): void {
        this.element.selectionStart = this.element.selectionEnd = pos;
    }

    setValues (filtered: PfFiltered): void {
        if (filtered.input.value != filtered.output.value) {
            if (
                filtered.difference.value
                && (filtered.difference.value.search(/\d/) < 0)
                && filtered.pfKeyDownEvent?.event?.which
                && ([8, 46].indexOf(this.pfKeyDownEvent.event.which) > -1)
            ) {
                // deleted a non-number. do nothing
            }
            else if (
                !filtered.valid
                && !filtered?.pfKeyDownEvent ?.caret?.atEnd
                && filtered?.pfKeyDownEvent ?.caret?.position
                && (filtered.input.value.substring(0, filtered.pfKeyDownEvent.caret.position) === filtered.output.value.substring(0, filtered.pfKeyDownEvent.caret.position))
            ) {
                // everything to the left was the same as it was before. don't change.
            }
            else if (filtered.difference.value) {
                if (
                    filtered?.pfKeyDownEvent ?.caret?.atEnd
                    || (
                        (filtered.difference.value.search(/\d/) > -1)
                        && (this?.pfKeyDownEvent ?.event?.key?.search(/\d/) > -1)
                    )
                ) {
                    this.setViewValue(filtered);
                }
                else if (filtered.becameValid) {
                    // valid again
                    this.setViewValue(filtered);
                }
            }
            else {
                this.setViewValue(filtered);
            }
        }
    }

    setViewValue (filtered: PfFiltered): void {
        this.element.value = filtered.output.value;
    }

    update (): PfFiltered  {
        const filtered = this.filter(this.element.value, this.bkPhoneFilter);
        this.setValues(filtered);
        if (
            this.onChange
            &&
            (filtered.output.value !== this.model)
        ) {
            this.onChange(filtered.output.value);
        }
        return filtered;
    }

    writeValue (val: any): void {
        // ng ControlValueAccessor
        this.element.value = val;
        this.update();
    }
}
