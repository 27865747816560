import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export class KCache
{
    // experimental. could be improved.

    afterGather: Array<(err?) => void> = [];
    cached: {[key: string]: any} = {};
    instigateGather = new Subject();
    thingsToGather: Array<any> = [];

    constructor (
        public gatherer: (thingsToGather: Array<any>) => Promise<any>,
        public stuffCache: (cached: {[key: string]: any}, getResult: any) => Promise<any>,
    ) {
        this.instigateGather.pipe(
            debounceTime(1)
        ).subscribe(() => {
            this.doGather();
        });
    }

    doGather (): Promise<any> {
        if (!this.thingsToGather.length) {
            return Promise.resolve();
        }
        return this.gatherer(this.thingsToGather).then((result: any) => {
            this.thingsToGather = [];
            return this.stuffCache(this.cached, result);
        }).then(() => {
            this.afterGather.forEach((agf) => {
                agf();
            });
            return;
        }).catch((err) => {
            this.afterGather.forEach((agf) => {
                agf(err);
            });
        });
    }

    gatherThing (thing: any): void {
        if (this.thingsToGather.indexOf(thing) < 0) {
            this.thingsToGather.push(thing);
        }
    }

    get (key: string): Promise<any> {
        if (this.cached[key]) {
            return Promise.resolve(this.cached[key]);
        }
        this.gatherThing(key);
        this.instigateGather.next(null);
        return new Promise((resolve, reject) => {
            this.afterGather.push((err?) => {
                if (err) {
                    return reject(err);
                }
                resolve(this.cached[key]);
            });
        });
    }
}
