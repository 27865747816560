import {
    Directive,
    ElementRef,
    HostListener,
    Input
} from '@angular/core';

import {
    SelectComponent,
} from './select.component';

/*
    event.keyCode
    40 down, 38 up, 39 right, 37 left
    8 backspace,
    9 tab,
    13 carriage return
    17 ctrl
    18 alt/option
    20 CAPSLOCK
    27 ESC
    32 space
    91 Meta
    93 ?
*/

@Directive({
    selector: '[bkSelectInput]',
    exportAs: 'SelectInputDirective'
})
export class SelectInputDirective {
    @Input() selectComponent: SelectComponent;
    @Input('ngModel') model: any;

    constructor (
        public el: ElementRef
    ) {
    }

    @HostListener('blur', ['$event'])
    onBlurHandler (e: FocusEvent): void {
        const sc = this.selectComponent;
        sc.hasFocus = false;
        if (sc.open && !sc.protected && !sc.protectedFromBlur) {
            sc.toggle(false);
        }
        sc.protected = false;
        sc.protectedFromBlur = false;
    }

    onDownHandler (e: any): void {
        this.selectComponent.toggle(true);
    }

    @HostListener('focus', ['$event'])
    onFocusHandler (e: FocusEvent): void {
        const sc = this.selectComponent;
        sc.hasFocus = true;
        sc.makeSuggestion(); // scope.item
    }

    @HostListener('input', ['$event'])
    onInputHandler (e: InputEvent): void {
        const sc = this.selectComponent;
        sc.filterList(sc.searchText);
    }

    @HostListener('keydown', ['$event'])
    onKeyDownHandler (e: KeyboardEvent): void {
        const sc = this.selectComponent;
        
        if (!sc.open) {
            if (e.keyCode && ([13].indexOf(e.keyCode) > -1)) {
                // carriage return
                return;
            }
            if ([9, 17, 18, 27, 91].indexOf(e.keyCode) > -1) {
                // IS one of these keys
                return;
            }
            if (e.keyCode && ([13, 16, 18, 20, 93].indexOf(e.keyCode) < 0)) {
                // IS NOT one of these keys
                sc.toggle(true);
                return;
            }
        }

        if (e.keyCode == 27) {
            // escape
            e.stopPropagation();
            //sc.toggle(false);
            return;
        }

        if ([9].indexOf(e.keyCode) > -1) {
            // tab
            if (sc.suggestedResult) { // sc.searchText.length && sc.list.length && sc.list[0] && sc.list[0].emptyListItem
                if (sc.searchText == sc.suggestedResultText) {
                    sc.selectItem(sc.suggestedResult);
                }
                else {
                    sc.searchText = sc.suggestedDisplay(sc.suggestedResult); // sc.suggestedDisplay(sc.list[0]);
                    e.preventDefault();
                }
            }
            else {
                sc.toggle(false);
            }
        }

        if ([13].indexOf(e.keyCode) > -1) {
            // carriage return
            e.stopPropagation();
            e.preventDefault();
            setTimeout(() => {
                if (sc.suggestedResult && (sc.searchText === sc.suggestedResultText)) {
                    // sc.searchText = sc.suggestedDisplay(sc.suggestedResult);
                    sc.selectItem(sc.suggestedResult);
                }
                else if (sc.searchText) {  //  && sc.addNew -> maybe option enterAddsNew? or something ...
                    sc.addNew.emit({
                        searchText: sc.searchText,
                        selectComponent: sc,
                    });
                }
                else if (!sc.searchText) {
                    sc.selectItemByValue();
                    sc.toggle(false);
                }
            });
        }

        if ([40].indexOf(e.keyCode) > -1) {
            // down
            if (sc.list[0] && sc.list[0].element) {
                e.preventDefault(); // stop scroll
                sc.protected = true
                sc.getNextShowingListItem()?.focus();
            }
        }
    }

    @HostListener('keyup', ['$event'])
    onKeyUpHandler (e: KeyboardEvent): void {
        const sc = this.selectComponent;
        
        if (sc.open) {
            if (e.keyCode === 27) {
                // escape
                e.stopPropagation();
                sc.toggle(false);
                return;
            }
        }
    }

    @HostListener('mousedown', ['$event'])
    onMouseDownHandler (e: MouseEvent): void {
        this.onDownHandler(e);
    }

    @HostListener('touchstart', ['$event'])
    onTouchStartHandler (e: TouchEvent): void {
        this.onDownHandler(e);
    }
}
