import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    // HttpErrorResponse,
    HttpEventType,
    HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
    selector: 'bk-file-upload-button',
    templateUrl: './file-upload-button.component.html',
    styleUrls: ['./file-upload-button.component.scss'],
    exportAs: 'bkFileUploadButton',
})
export class FileUploadButtonComponent implements OnInit, AfterViewInit {
    @Input() accept: string;
    @Output() complete = new EventEmitter<any>();
    @ViewChild('fileInput') fileInput: ElementRef;
    fileToUpload: File = null;
    loadingForUpload: boolean = false;
    selecting: boolean = false;
    @Output() selected = new EventEmitter<FileUploadButtonComponent>();
    @Output() status = new EventEmitter<FileUploadButtonComponent>();
    uploadedSuccessfully: boolean = false;
    uploadError: any;
    uploading: boolean = false;
    @Input() uploadMethod: (options: {
        fileToUpload: File;
    }) => Observable<any>;
    uploadProgressPercent: number;

    constructor (
    ) {
    }

    clickFileInput (): void {
        this.status.emit(this);
        this.fileInput.nativeElement.click();
        this.selecting = true;
    }

    fileChanged (event: any): void {
        this.selecting = false;
        const files: FileList = event.target.files;
        this.fileToUpload = files.item(0);
        this.status.emit(this);
        this.selected.emit(this);
    }

    ngAfterViewInit (): void {
    }

    ngOnInit (): void {
    }

    upload (): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.uploading) {
                return resolve(null);
            }
    
            this.loadingForUpload = true;
            this.uploadedSuccessfully = false;
            this.uploading = true;
            delete this.uploadError;
    
            this.status.emit(this);
    
            const uploadObservable = this.uploadMethod({
                fileToUpload: this.fileToUpload,
            });
    
            uploadObservable.subscribe({
                next: (event: any) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        this.uploadProgressPercent = Math.round(100 * event.loaded / event.total);
                    }
                    else if (event instanceof HttpResponse) {
                        this.uploadedSuccessfully = true;
                        // event.body.attachment? event.body.file?
                    }
                },
                error: (err) => {
                    this.uploadError = err;
                    //if (err instanceof HttpErrorResponse) {
                    //}
                    reject(err);
                },
                complete: () => {
                    this.uploading = false;
                    this.loadingForUpload = false;
                    this.status.emit(this);
                    this.complete.emit(this);
                    resolve(this);
                },
            });
        });
    }
}
