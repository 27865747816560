import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { EnvService } from 'bkng';

import { IApplication } from '@amt/i-application';
import { ILog } from '@amt/i-log';
import { IApplicationLogsSearchOptions } from '@amt/i-application-logs-search-options';

import { ApplicationService } from '../application.service';

@Component({
    selector: 'app-application-logs',
    templateUrl: './application-logs.component.html',
    styleUrls: ['./application-logs.component.scss']
})
export class ApplicationLogsComponent implements OnInit, OnDestroy {
    application: IApplication;
    @Input() applicationId: string;
    dayFirstLog: {
        [id: string]: Date | string;
    } = {};
    env: any;
    fetchedLogs: boolean = false;
    fetchingLogs: boolean = false;
    getLogsSubject: Subject<any> = new Subject();
    getLogsSubscription: Subscription;
    logs: Array<ILog> = [];
    @ViewChild('logsList') public logsList: ElementRef;

    constructor (
        public route: ActivatedRoute,
        public envService: EnvService,
        public applicationService: ApplicationService,
    ) {
        this.env = envService.data;

        this.getLogsSubscription = this.getLogsSubject.pipe(
            debounceTime(255)
        ).subscribe(() => {
            this.getLogs();
        });

        this.route.queryParams.subscribe((params) => {
            this.logs = [];
        });
    }

    conditionLogs (): void {
        for (let i = 0; i < this.logs.length; i++) {
            const lastLog = this.logs[i - 1];
            const log = this.logs[i];
            const lastLogDay = lastLog ? (new Date(lastLog.time)).getDate() : undefined;
            const logDay = log ? (new Date(log.time)).getDate() : undefined;
            
            if (
                !lastLogDay
                ||
                (
                    (logDay && lastLogDay)
                    &&
                    (logDay > lastLogDay)
                )
            ) {
                this.dayFirstLog[log.id] = log.time;
            }
        }
    }

    getLogs (): void {
        if (this.fetchingLogs) {
            return;
        }

        this.fetchingLogs = false;

        const getLogsOptions: IApplicationLogsSearchOptions = {};

        if (this.route.snapshot.queryParams.class) {
            getLogsOptions.class = this.route.snapshot.queryParams.class;
        }

        if (this.logs.length) {
            getLogsOptions.after_num = this.logs[this.logs.length - 1].num;
        }

        if (this.route.snapshot.queryParams.since) {
            getLogsOptions.since = this.route.snapshot.queryParams.since;
        }

        this.applicationService.getApplicationLogs(this.applicationId, getLogsOptions).then((response: {
            logs: Array<ILog>
        }) => {
            const llEl = this.logsList.nativeElement;

            const bottomOfElementIsAt = Math.ceil(llEl.clientHeight + llEl.scrollTop);
            const scrollHeightIs = Math.floor(llEl.scrollHeight);

            const atEnd = bottomOfElementIsAt >= (scrollHeightIs - 15);

            const newLogs: Array<ILog> = ([].concat(response.logs)).reverse();

            if (newLogs.length && !this.fetchedLogs) {
                console.log(`Log sample`, newLogs[newLogs.length - 1]);
            }

            this.logs = this.logs.concat(newLogs);
            
            this.conditionLogs();
                        
            setTimeout(() => {
                if (atEnd) {
                    if (this.fetchedLogs) {
                        llEl.scrollTo({top: llEl.scrollHeight, behavior: 'smooth'});
                    }
                    else {
                        llEl.scrollTop = llEl.scrollHeight;
                    }
                }
                this.fetchedLogs = true;
            }, 1);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            this.fetchingLogs = false;
            this.getLogsSubject.next(null);
        });
    }

    ngOnDestroy (): void {
        this.getLogsSubscription.unsubscribe();
    }

    ngOnInit (): void {
        this.getLogs();
    }
}
